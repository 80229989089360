import {
  findMatchStateByGroupIdIndex,
  findMatchStateByMatchIdIndex,
} from './match.helpers';
import { MatchesActions } from './matches.actions';
import {
  GET_MATCH_ERROR_TYPE,
  GET_MATCH_SUCCESS_TYPE,
  GET_MATCHES_SUCCESS_TYPE,
  UPDATE_MATCH_STATUS_SUCCESS_TYPE,
} from './matches.actions';
import { Match, MatchState } from '@libs/src/models/match.interfaces';

import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import omit from 'lodash-es/omit';

const DEFAULT_MATCHES_STATE: MatchState[] = [];

export function matchesReducer(
  state: MatchState[] = DEFAULT_MATCHES_STATE,
  action: MatchesActions,
) {
  switch (action.type) {
    case GET_MATCHES_SUCCESS_TYPE: {
      return [
        ...state,
        ...action.payload.map((match: Match) => ({
          document: match,
          isLoaded: true,
        })),
      ];
    }

    case GET_MATCH_SUCCESS_TYPE: {
      const stateClone = cloneDeep(state);
      const existingMatchForGroupIndex = findMatchStateByGroupIdIndex(
        stateClone,
        action.payload.group?._id,
      );
      const newMatchState = {
        document: action.payload,
        isLoaded: true,
      };
      if (0 <= existingMatchForGroupIndex) {
        stateClone[existingMatchForGroupIndex] = newMatchState;
      } else {
        stateClone.push(newMatchState);
      }
      return stateClone;
    }

    case UPDATE_MATCH_STATUS_SUCCESS_TYPE: {
      const existingMatchIndex = findMatchStateByMatchIdIndex(
        state,
        action.payload._id,
      );
      const clonedState = cloneDeep(state);
      if (0 <= existingMatchIndex) {
        clonedState[existingMatchIndex].document = action.payload;
      } else {
        clonedState.push({ document: action.payload, isLoaded: true });
      }
      return clonedState;
    }

    case GET_MATCH_ERROR_TYPE: {
      const stateClone = cloneDeep(state);
      const matchStateWithErrorIndex = findMatchStateByGroupIdIndex(
        stateClone,
        action.payload.group._id,
      );
      const matchStateWithError = {
        isLoaded: true,
        error: action.payload.error,
      };
      if (0 <= matchStateWithErrorIndex) {
        stateClone[matchStateWithErrorIndex] = {
          ...matchStateWithError,
          document: {
            ...get(stateClone, `[${matchStateWithErrorIndex}].document`, {}),
            group: action.payload.group,
          },
        };
      } else {
        stateClone.push({
          ...matchStateWithError,
          document: {
            ...omit(action.payload, ['error']),
            group: action.payload.group,
          },
        });
      }
      return stateClone;
    }

    default: {
      return state;
    }
  }
}
