import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { EmailVerificationDialogComponent } from '@main-client/src/app/email-verification-dialog/email-verification-dialog.component';

import { EmailVerificationDialogData } from '@main-client/src/app/email-verification-dialog/email-verification-dialog-data';

@Injectable()
export class EmailVerificationDialogService {
  constructor(public dialog: MatDialog) {}

  openDialog(data: EmailVerificationDialogData) {
    return this.dialog.open(EmailVerificationDialogComponent, {
      data,
      maxWidth: '400px',
      width: '95vw',
    });
  }
}
