import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import type { TrackParams } from '@segment/analytics-node';
import { Observable, of } from 'rxjs';

import { AuthService } from '@main-client/src/app/auth/auth.service';
import { WindowRefService } from '@main-client/src/app/window-ref.service';

import { sendErrorReport } from './error-logger.helper';
import { environment } from '@libs/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly http: HttpClient,
    private readonly injector: Injector,
    private readonly windowService: WindowRefService,
  ) {}

  protected getAuthTokenPayload() {
    const authService = this.injector.get(AuthService);
    return authService.$authTokenPayload();
  }

  logInfo(options: {
    anonymousId?: string;
    message: string;
    properties?: TrackParams['properties'];
  }): Observable<object | null> {
    if (!environment.production) {
      return of(null);
    }
    const decodedAuthToken = this.getAuthTokenPayload();
    const isSuperAdmin = !!decodedAuthToken?.superAdmin;
    const userId = decodedAuthToken?.superAdmin ?? decodedAuthToken?.accountId;
    return this.http.post('/__logs', {
      userId,
      anonymousId: options.anonymousId,
      message: options.message,
      properties: {
        isSuperAdmin,
        assumedUser: isSuperAdmin
          ? { _id: decodedAuthToken?.accountId }
          : undefined,
        ...options.properties,
      },
      referrer: this.getReferrer(),
      url: this.windowService.nativeWindow.location.href,
      userAgent: this.getUserAgent(),
    });
  }

  logError(options: { context?: Record<string, unknown>; message: string }) {
    if (!environment.production) {
      return;
    }
    const decodedAuthToken = this.getAuthTokenPayload();
    const userId = decodedAuthToken?.superAdmin ?? decodedAuthToken?.accountId;
    sendErrorReport({
      context: { ...options.context, authToken: decodedAuthToken },
      message: options.message,
      referrer: this.getReferrer(),
      url: this.windowService.nativeWindow.location.href,
      user: userId,
      userAgent: this.getUserAgent(),
    });
  }

  private getReferrer() {
    return this.document?.referrer;
  }

  private getUserAgent() {
    return this.windowService.nativeWindow?.navigator?.userAgent;
  }
}
