import {
  DEFAULT_LOCALE_CODE,
  LOCALE_CODES,
  SUPPORTED_LOCALES,
  SupportedLocaleCode,
} from '@ttc/ttc-constants/locale';

import { Locale } from '@libs/src/locale/locale.interface';

import includes from 'lodash-es/includes';

export const locales: Locale[] = [
  {
    bilingualName:
      $localize`:@@global.localeEnUS:English (US)` + ' - English (US)',
    code: LOCALE_CODES.enUS,
    locationName: 'United States',
    nativeDisplayName: 'English',
    translatedName: $localize`:@@global.localeEnUS:English (US)`,
  },
  {
    bilingualName: $localize`:@@global.localeEs:Spanish` + ' - Español',
    code: LOCALE_CODES.es,
    locationName: 'Latinoamérica',
    nativeDisplayName: 'Español',
    translatedName: $localize`:@@global.localeEs:Spanish`,
  },
  {
    bilingualName:
      $localize`:@@global.localeFrCA:French (Canada)` + ' - Français (Canada)',
    code: LOCALE_CODES.frCA,
    locationName: 'Canada',
    nativeDisplayName: 'Français',
    translatedName: $localize`:@@global.localeFrCA:French (Canada)`,
  },
  {
    bilingualName: $localize`:@@global.localeKo:Korean` + ' - 한국어',
    code: LOCALE_CODES.ko,
    locationName: '대한민국',
    nativeDisplayName: '한국어',
    translatedName: $localize`:@@global.localeKo:Korean`,
  },
  {
    bilingualName: $localize`:@@global.localeJa:Japanese` + ' - やまと',
    code: LOCALE_CODES.ja,
    locationName: '日本',
    nativeDisplayName: 'やまと',
    translatedName: $localize`:@@global.localeJa:Japanese`,
  },
];

export const defaultLocale = locales.find(
  (locale) => locale.code === DEFAULT_LOCALE_CODE,
);

export function isSupportedLocale(localeCode: string): boolean {
  return includes(SUPPORTED_LOCALES, localeCode);
}

export function getLocaleBilingualName(
  localeCode: SupportedLocaleCode,
): string {
  return locales.find((locale) => locale.code === localeCode)?.bilingualName;
}

export function getLocaleDisplayName(localeCode: SupportedLocaleCode): string {
  return locales.find((locale) => locale.code === localeCode)
    ?.nativeDisplayName;
}
