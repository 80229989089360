import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ZendeskBackend {
  constructor(private readonly http: HttpClient) {}

  authenticate() {
    return this.http.get<{ token: string }>('/api/v3/zendesk/authenticate');
  }

  fetchSsoToken() {
    return this.http.get<{ token?: string }>('/api/v3/zendesk/token/sso');
  }
}
