import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { Tenant } from '@libs/src/tenant/tenant.interface';
import { IAppState } from '@main-client/src/app/app.state';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

@Injectable({
  providedIn: 'root',
})
export class CanLoadConversationsModuleGuardService implements CanLoad {
  constructor(
    private readonly router: Router,
    private readonly store: Store<IAppState>,
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<true | UrlTree> {
    return this.store.select('tenant').pipe(
      filter((tenant: Tenant) => !isEmpty(tenant)),
      take(1),
      map((tenant: Tenant) => {
        const isEnabled = get(tenant, 'enabled_features.conversations');
        return isEnabled || this.router.createUrlTree(['/home']);
      }),
    );
  }
}
