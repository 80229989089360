import { Action } from '@ngrx/store';

export const NOTIFICATIONS_PATCH_TYPE = '[notifications] Patch';
export const NOTIFICATIONS_LOADED_TYPE = '[notifications] Loaded';
export const NOTIFICATIONS_SEEN_ALL_TYPE = '[notifications] Seen All';

export class NotificationsAction implements Action {
  constructor(
    public type: string = '',
    public payload: any = {},
  ) {}
}

export function PatchNotification(payload: any): NotificationsAction {
  return new NotificationsAction(NOTIFICATIONS_PATCH_TYPE, payload);
}

export function LoadedNotifications(payload: any): NotificationsAction {
  return new NotificationsAction(NOTIFICATIONS_LOADED_TYPE, payload);
}

export function SeenAllNotifications(): NotificationsAction {
  return new NotificationsAction(NOTIFICATIONS_SEEN_ALL_TYPE);
}
