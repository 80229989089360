import type { FactoryProvider } from '@angular/core';
import { JWT_OPTIONS } from '@auth0/angular-jwt';
import type { JwtConfig } from '@auth0/angular-jwt';

import { AuthService } from './auth.service';
import { TENANT_TOKEN_KEY } from '@main-client/src/app/core/local-storage.service';

const jwtConfig: Omit<JwtConfig, 'tokenGetter'> = {};

export function provideJwtOptions(): FactoryProvider {
  return {
    deps: [AuthService],
    provide: JWT_OPTIONS,
    useFactory: (authService: AuthService): JwtConfig => ({
      ...jwtConfig,
      tokenGetter: () => {
        const authToken = authService.getAuthToken();
        if (authToken && localStorage.getItem(TENANT_TOKEN_KEY)) {
          return authToken;
        }
        return null;
      },
    }),
  };
}
