import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { ToastService } from './toast.service';

import { CustomToastComponent } from './custom-toast.component';

@NgModule({
  declarations: [CustomToastComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButton,
    MatIconModule,
    MatSnackBarModule,
  ],
  providers: [ToastService],
})
export class ToastModule {}
