import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const SIGNUP_API_URL = '/api/auth/signup';

export interface SignUpData {
  email: string;
  first_name: string;
  inviteCode?: string;
  last_name: string;
  preRegistrationId?: string;
  signup_method?: string;
  signup_method_details?: string;
}

export interface SignUpParams {
  redirectState?: string;
  token?: string;
}

@Injectable()
export class AuthBackend {
  constructor(private readonly http: HttpClient) {}

  signUp(data: SignUpData, params?: SignUpParams) {
    return this.http.post(SIGNUP_API_URL, data, {
      params: params as any,
    });
  }
}
