import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { IAppState } from '../app.state';

export const DEFAULT_PAGE_TITLE = 'Ten Thousand Coffees';

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private readonly store: Store<IAppState>,
  ) {
    super();
  }

  override async updateTitle(
    routerState: RouterStateSnapshot,
    forcedPageTitle: any = null,
  ) {
    const routerPageTitle = forcedPageTitle ?? this.buildTitle(routerState);
    const { unseenConversationsCount, unseenNotificationsCount } =
      (await firstValueFrom(this.store.select('header'))) || {};
    const notificationsCount: number =
      unseenConversationsCount + unseenNotificationsCount;
    const titleCount = notificationsCount ? `(${notificationsCount}) ` : '';
    const pageTitle = `${titleCount}${
      routerPageTitle ? `${routerPageTitle} - ` : ''
    }${DEFAULT_PAGE_TITLE}`;
    this.title.setTitle(pageTitle);
  }
}
