/* global google */
// eslint-disable-next-line spaced-comment
/// <reference types="google.maps" />
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';

import { GlobalErrorHandler } from '@libs/src/global-error-handler/global-error-handler.service';
import { LoggerService } from '@libs/src/logger/logger.service';

import { environment } from '@libs/src/environments/environment';
import type { Address } from '@libs/src/location-autocomplete/location-autocomplete.types';

@Injectable()
export class GeocodeService {
  private readonly geocoder$: Observable<google.maps.Geocoder>;

  constructor(
    @Inject(ErrorHandler) private readonly errorHandler: GlobalErrorHandler,
    private readonly loggerService: LoggerService,
  ) {
    const loader = new Loader({
      apiKey: environment.googleMaps.apiKey,
    });
    this.geocoder$ = new Observable((subscriber) => {
      loader.importLibrary('geocoding').then(
        (geocoder) => {
          if (!subscriber.closed) {
            subscriber.next(new geocoder.Geocoder());
            subscriber.complete();
          }
        },
        (error) => {
          subscriber.error(error);
        },
      );
    });
  }

  getLocationFromGeocode(
    location: string | undefined,
  ): Observable<Address | undefined> {
    if (!location?.trim()) {
      this.errorHandler.logError(
        new Error(
          'GeocodeService.getLocationFromGeocode called with empty location',
        ),
      );
      return of(undefined);
    }
    return this.geocoder$.pipe(
      mergeMap((geocoder) => from(geocoder.geocode({ address: location }))),
      take(1),
      map(({ results }): Address | undefined => {
        const geometry = results[0]?.geometry;
        if (!geometry) {
          return;
        }
        return {
          address: results[0]?.formatted_address,
          bounds: geometry.bounds?.toJSON(),
          location: {
            coordinates: [geometry.location.lng(), geometry.location.lat()],
            type: 'Point',
          },
        };
      }),
      catchError((error) => {
        this.loggerService.logError(error.toString());
        return of(undefined);
      }),
    );
  }

  getCustomLabelFromCoordinates(
    lat: number,
    lng: number,
  ): Observable<string | undefined> {
    return this.geocoder$.pipe(
      mergeMap((geocoder) =>
        from(geocoder.geocode({ location: { lat, lng } })),
      ),
      take(1),
      map(({ results }) => {
        const resultWithNeighborhood = results.find((result) =>
          result?.types?.includes('neighborhood'),
        );
        const fallbackCustomLabel = results[0]?.formatted_address;
        return resultWithNeighborhood?.formatted_address ?? fallbackCustomLabel;
      }),
      catchError((error) => {
        this.loggerService.logError(error.toString());
        return of(undefined);
      }),
    );
  }
}
