import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, filter, map, take } from 'rxjs/operators';

import { Account } from '@libs/src/models/account.model';
import { isAdminOfGroup } from '@main-client/src/app/user/user.helpers';

import { IAppState } from '../app.state';

import isEmpty from 'lodash-es/isEmpty';

@Injectable()
export class GroupAdminGuardService implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<IAppState>,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.store.select('currentGroup').pipe(
      concatMap((currentGroupId: string) => {
        const groupId = currentGroupId || route.queryParamMap.get('group');
        if (!groupId) {
          return of(this.router.createUrlTree(['/']));
        }
        return this.store.select('user').pipe(
          filter((user: Account) => !isEmpty(user)),
          take(1),
          map((user: Account) => {
            const userCanActivateRoute =
              user.isAdmin || isAdminOfGroup(user, { _id: groupId });
            return userCanActivateRoute || this.router.createUrlTree(['/']);
          }),
        );
      }),
    );
  }
}
