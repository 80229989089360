import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MostReadableColorPipe } from '@main-client/src/app/pipes/most-readable-color/most-readable-color.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [MostReadableColorPipe],
  exports: [MostReadableColorPipe],
  providers: [MostReadableColorPipe],
})
export class MostReadableColorModule {}
