import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, take } from 'rxjs/operators';

import { ToastService } from '@libs/src/toast/toast.service';
import { IAccountPatchResponse } from '@main-client/src/app/user/user-mutation.service';

import {
  GROUPS_UPDATE_ROLE_SUCCESS_TYPE,
  GROUPS_UPDATE_SUCCESS_TYPE,
  GROUPS_UPDATE_TYPE,
  GroupsAction,
} from '@libs/src/groups/groups-store/groups.actions';
import {
  Account,
  AccountGroupMembership,
} from '@libs/src/models/account.model';
import { Group } from '@libs/src/models/group.model';
import {
  SetUser,
  SetUserSingleGroup,
  USER_INIT_SAVE_TYPE,
  UserAction,
} from '@libs/src/user/user.actions';
import { IAppState } from '@main-client/src/app/app.state';

import find from 'lodash-es/find';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import keys from 'lodash-es/keys';
import pick from 'lodash-es/pick';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';

const ACCOUNT_API_URL = '/api/account';
const SANITIZED_USER_ARRAY_KEYS = [
  {
    userKey: 'geo_location',
    keys: ['address'],
  },
  {
    userKey: 'experience',
    keys: ['job_title', 'organization_name'],
  },
];

@Injectable()
export class UserEffects {
  updateSingleGroup: Observable<UserAction> = createEffect(() =>
    this.actions.pipe(
      ofType(GROUPS_UPDATE_TYPE),
      map((action: GroupsAction) =>
        SetUserSingleGroup({ group: action.payload.group }),
      ),
    ),
  );

  updateSingleGroupSuccess: Observable<UserAction> = createEffect(() =>
    this.actions.pipe(
      ofType(GROUPS_UPDATE_SUCCESS_TYPE),
      map((action: GroupsAction) =>
        SetUserSingleGroup({ group: action.payload.group }),
      ),
    ),
  );

  saveUser: Observable<UserAction> = createEffect(() =>
    this.actions.pipe(
      ofType(USER_INIT_SAVE_TYPE),
      concatMap((action: any) => {
        return this.store.select('user').pipe(
          take(1),
          concatMap((userState: any) => {
            const resetUserState = {
              ...pick(userState, keys(action.payload.user)),
            };
            const sanitizedUserUpdate = this.sanitizeUserArrayKeys(
              action.payload.user,
            );
            return this.patchUser(
              sanitizedUserUpdate,
              resetUserState,
              action.payload.redirectPathOnError,
            ).pipe(map((update: any) => SetUser(update)));
          }),
        );
      }),
    ),
  );

  updateSingleGroupRoles: Observable<UserAction> = createEffect(() =>
    this.actions.pipe(
      ofType(GROUPS_UPDATE_ROLE_SUCCESS_TYPE),
      concatMap((action: any) => {
        return this.store.select('user').pipe(
          take(1),
          map((userState: Account) => {
            const targetMembership = find(userState.groups, {
              document: { _id: action.payload.group._id },
            }) as AccountGroupMembership;
            return targetMembership && targetMembership.document;
          }),
          filter((targetGroup: Group) => !isEmpty(targetGroup)),
          map((targetGroup: Group) => {
            return SetUserSingleGroup({
              group: {
                ...targetGroup,
                roles: action.payload.roles,
              },
            });
          }),
        );
      }),
    ),
  );

  constructor(
    private actions: Actions,
    private http: HttpClient,
    private router: Router,
    private store: Store<IAppState>,
    private toastService: ToastService,
  ) {}

  patchUser(
    update: Account,
    resetUser: Account,
    redirectPathOnError: any,
  ): Observable<any> {
    return this.http.patch(ACCOUNT_API_URL, update).pipe(
      map((response: IAccountPatchResponse) => {
        return {
          ...update,
          ...pick(response.data, keys(update)),
        };
      }),
      catchError((response: any) => {
        const error = get(response, 'error.message', response.message);
        this.toastService.showError(error);
        setTimeout(() => this.router.navigate(redirectPathOnError), 0);
        this.store.dispatch(SetUser(resetUser));
        return throwError(error);
      }),
    );
  }

  sanitizeUserArrayKeys(user: Account) {
    return reduce(
      SANITIZED_USER_ARRAY_KEYS,
      (result: Account, keyToSanitize: any) => {
        const value = (result as any)[keyToSanitize.userKey] || [];
        const filteredValue = value.filter(
          (data: any) => !isEmpty(pickBy(pick(data, keyToSanitize.keys))),
        );
        return {
          ...result,
          [keyToSanitize.userKey]: filteredValue,
        };
      },
      { ...user },
    );
  }
}
