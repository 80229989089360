import includes from 'lodash-es/includes';
import startsWith from 'lodash-es/startsWith';
import trim from 'lodash-es/trim';

const PROTOCOL = 'http://';
const RESERVED_SUBDOMAINS = ['www'];
const SECURE_PROTOCOL = 'https://';

export function getCurrentSubdomain() {
  return getSubdomainFromUrl(window.location.host);
}

export function generateSubdomainUrl(subdomainName: string) {
  const urlBreakdown = [
    `${window.location.protocol}`,
    '//',
    `${subdomainName || 'www'}.`,
    getDomainFromUrl(window.location.host),
  ];
  return urlBreakdown.join('');
}

export function isReservedSubdomain() {
  return includes(
    RESERVED_SUBDOMAINS,
    getSubdomainFromUrl(window.location.host),
  );
}

function getDomainFromUrl(url: string) {
  const splitter = '.';
  const [, ...domain] = url.split(splitter);
  return domain.join(splitter);
}

function getSubdomainFromUrl(url: string) {
  const splitter = '.';
  const [subdomain] = url.split(splitter);
  return subdomain;
}

export function getLocationOrigin() {
  return window.location.origin;
}

export function sanitizeUrlWithProtocol(url: string) {
  if (!url) {
    return '';
  }
  return startsWith(url, PROTOCOL) || startsWith(url, SECURE_PROTOCOL)
    ? trim(url)
    : trim(`${PROTOCOL}${url}`);
}
