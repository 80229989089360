import {
  animate,
  animation,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeInEnter = trigger('fadeInEnter', [
  transition(
    ':enter',
    animate(
      '300ms cubic-bezier(0.4, 0.0, 0.2, 1)',
      keyframes([style({ opacity: '0' }), style({ opacity: '1' })]),
    ),
  ),
]);

export const fadeInGrowEnter = trigger('fadeInGrowEnter', [
  transition(
    ':enter',
    animate(
      '300ms cubic-bezier(0.4, 0.0, 0.2, 1)',
      keyframes([
        style({ opacity: '0', height: '0', overflow: 'hidden' }),
        style({ opacity: '1', height: '*', overflow: 'hidden' }),
      ]),
    ),
  ),
]);

export const fadeOutLeave = trigger('fadeOutLeave', [
  transition(
    ':leave',
    animate(
      '300ms cubic-bezier(0.4, 0.0, 0.2, 1)',
      keyframes([style({ opacity: '1' }), style({ opacity: '0' })]),
    ),
  ),
]);

export const shrinkLeave = trigger('shrinkLeave', [
  transition(
    ':leave',
    animate(
      '300ms cubic-bezier(0.4, 0.0, 0.2, 1)',
      keyframes([
        style({ opacity: '1', height: '*', overflow: 'hidden' }),
        style({
          opacity: '0',
          height: '0',
          paddingTop: 0,
          paddingBottom: 0,
          overflow: 'hidden',
        }),
      ]),
    ),
  ),
]);

export const rotate90 = trigger('rotate90', [
  state('true', style({ transform: 'rotate(90deg)' })),
  state('false', style({ transform: 'rotate(0)' })),
  transition('false <=> true', animate(100)),
]);

export const rotate180 = trigger('rotate180', [
  state('true', style({ transform: 'rotate(180deg)' })),
  state('false', style({ transform: 'rotate(0)' })),
  transition('false <=> true', animate(100)),
]);

export const shake = animation(
  animate(
    '{{ timing }}s {{ delay }}s',
    keyframes([
      style({ transform: 'translate3d(0, 0, 0)', offset: 0 }),
      style({ transform: 'translate3d(-2px, 0, 0)', offset: 0.1 }),
      style({ transform: 'translate3d(2px, 0, 0)', offset: 0.2 }),
      style({ transform: 'translate3d(-2px, 0, 0)', offset: 0.3 }),
      style({ transform: 'translate3d(2px, 0, 0)', offset: 0.4 }),
      style({ transform: 'translate3d(-2px, 0, 0)', offset: 0.5 }),
      style({ transform: 'translate3d(2px, 0, 0)', offset: 0.6 }),
      style({ transform: 'translate3d(-2px, 0, 0)', offset: 0.7 }),
      style({ transform: 'translate3d(2px, 0, 0)', offset: 0.8 }),
      style({ transform: 'translate3d(-2px, 0, 0)', offset: 0.9 }),
      style({ transform: 'translate3d(0, 0, 0)', offset: 1 }),
    ]),
  ),
  { params: { timing: 0.5, delay: 0 } },
);

export const getSlideEnterAnimation = trigger('slideIn', [
  transition(':enter', [
    style({ transform: 'translateX({{slideInOffset}})' }),
    animate('400ms ease-out', style({ transform: 'translateX(0%)' })),
  ]),
]);

export function getSlideInTranslateOffset(direction: 'left' | 'right') {
  return 'left' === direction ? '50%' : '-50%';
}
