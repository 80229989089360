import { Component, Input } from '@angular/core';
import type { ValueOf } from 'ts-essentials';

import type { Tenant } from '@libs/src/tenant/tenant.interface';

import type { AvatarComponent } from '../avatar.component';

type AvatarSize = ValueOf<AvatarComponent['AVATAR_SIZES']>;

@Component({
  selector: 'app-avatar-tenant',
  templateUrl: './avatar-tenant.component.html',
})
export class AvatarTenantComponent {
  @Input()
  item: Tenant;
  @Input()
  size: AvatarSize;
}
