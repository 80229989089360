import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';

import { EmailVerificationDialogService } from '@main-client/src/app/email-verification-dialog/email-verification-dialog.service';

import { Account } from '@libs/src/models/account.model';
import { hasFinishedOnboarding } from '@main-client/src/app/account/account.helpers';
import { IAppState } from '@main-client/src/app/app.state';
import {
  USER_ONBOARDING_ROUTE_PATHS,
  USER_ONBOARDING_ROUTES_BASE_PATH,
} from '@main-client/src/app/user-onboarding/user-onboarding.helper';
import {
  hasSignedUpViaOfficeHours,
  isActiveUser,
} from '@main-client/src/app/user/user.helpers';

import isEmpty from 'lodash-es/isEmpty';

@Injectable()
export class ActiveUserGuard implements CanActivate, CanActivateChild {
  constructor(
    public emailVerificationDialogService: EmailVerificationDialogService,
    private router: Router,
    private store: Store<IAppState>,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.activeUserGuard(route, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    return this.activeUserGuard(childRoute, state);
  }

  activeUserGuard(
    newRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    return this.store.select('user').pipe(
      filter((user: Account) => !isEmpty(user)),
      take(1),
      map((user: Account) => {
        if (isActiveUser(user)) {
          return true;
        }
        if (!user.email_is_verified && hasSignedUpViaOfficeHours(user)) {
          return this.router.createUrlTree(['/verify-email-prompt']);
        }
        if (!hasFinishedOnboarding(user)) {
          return this.router.createUrlTree([
            USER_ONBOARDING_ROUTES_BASE_PATH,
            USER_ONBOARDING_ROUTE_PATHS.hubSegments,
          ]);
        }
        const profileSlug = newRoute.paramMap.get('profileSlug');
        if (profileSlug === user.slug) {
          return true;
        }
        this.emailVerificationDialogService.openDialog({ user });
        return this.router.createUrlTree(['/profile', user.slug]);
      }),
    );
  }
}
