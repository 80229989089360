import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { EmailVerificationDialogService } from '@main-client/src/app/email-verification-dialog/email-verification-dialog.service';

import { EmailVerificationDialogComponent } from '@main-client/src/app/email-verification-dialog/email-verification-dialog.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, MatDialogModule],
  declarations: [EmailVerificationDialogComponent],
  providers: [EmailVerificationDialogService],
})
export class EmailVerificationDialogModule {}
