import { HttpParams } from '@angular/common/http';

export function createHttpParamsFromObject(
  document: Record<string, string[] | boolean | number | string>,
): HttpParams {
  const keys = Object.keys(document).filter((key) => !!document[key]);
  let params = new HttpParams();
  keys.forEach((key) => {
    const value = document[key];
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params = params.append(key, item);
      });
    } else {
      params = params.set(key, value);
    }
  });
  return params;
}
