import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { isAdminOfGroup } from '@main-client/src/app/user/user.helpers';

import { Account, AccountSegmentSelection } from '../models/account.model';
import {
  Group,
  GroupCustomSegment,
  GroupCustomSegmentTypes,
  GroupHiddenCustomSegment,
} from '../models/group.model';

import cloneDeep from 'lodash-es/cloneDeep';
import find from 'lodash-es/find';
import findIndex from 'lodash-es/findIndex';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

export function buildFormFromSegmentSelections(
  customSegments: GroupCustomSegment[] = [],
  selections: AccountSegmentSelection[],
): UntypedFormGroup {
  const fb = new UntypedFormBuilder();
  const controlsConfig = customSegments.reduce((acc, segment) => {
    const selection = getSegmentationSelection(selections, segment);
    const selectionDisplay =
      'multi' === segment.selection_type ? 'multiselected_nodes' : 'node';
    return {
      ...acc,
      [segment._id]: selection ? [selection[selectionDisplay]] : [''],
    };
  }, {});
  return fb.group(controlsConfig);
}

export function updateSegmentSelections(
  segmentSelections: AccountSegmentSelection[] = [],
  segment: GroupCustomSegment,
  nodeNamesUpdate: string[],
) {
  const selectionsClone = cloneDeep(segmentSelections);
  const targetSelection =
    getSegmentationSelection(selectionsClone, segment) || {};
  const segmentId = segment._id;
  const nodeSelectionUpdate =
    'multi' === segment.selection_type
      ? { multiselected_nodes: nodeNamesUpdate }
      : { node: nodeNamesUpdate[0] };
  const updatedSelection: AccountSegmentSelection = {
    ...targetSelection,
    ...nodeSelectionUpdate,
    custom_segment: segmentId,
  };
  if (isEmpty(targetSelection)) {
    selectionsClone.push(updatedSelection);
  } else {
    const targetIndex = findIndex(segmentSelections, {
      custom_segment: segmentId,
    });
    selectionsClone[targetIndex] = updatedSelection;
  }
  return selectionsClone;
}

export function getSegmentationSelection(
  segmentSelections: AccountSegmentSelection[],
  segment: GroupCustomSegmentTypes,
) {
  return segmentSelections?.find(
    (segmentSelection) => segmentSelection.custom_segment === segment._id,
  );
}

export function getSegmentSelectionNodes(
  customSegments: GroupCustomSegment[],
  segmentSelection: Partial<AccountSegmentSelection>,
) {
  return 'multi' ===
    find(customSegments, { _id: segmentSelection.custom_segment })
      ?.selection_type
    ? segmentSelection.multiselected_nodes
    : segmentSelection.node;
}

export function getUserSelectedNodesForCustomSegment(
  userSegmentSelections: AccountSegmentSelection[],
  customSegment: GroupCustomSegment,
  defaultValues?: { multiSelectDefault: string[]; singleSelectDefault: string },
) {
  const targetSelection = getSegmentationSelection(
    userSegmentSelections,
    customSegment,
  );
  const isMultiSelectSegment = 'multi' === customSegment.selection_type;
  const userValue = isMultiSelectSegment
    ? targetSelection?.multiselected_nodes
    : targetSelection?.node;
  if (!isEmpty(userValue)) {
    return userValue;
  }
  if (isEmpty(defaultValues)) {
    return;
  }
  return isMultiSelectSegment
    ? defaultValues.multiSelectDefault
    : defaultValues.singleSelectDefault;
}

export function getHiddenSegmentsVisibleToUser(
  group: Group,
  user: Account,
  account: Account,
) {
  const isUser = user?._id === account?._id;
  const isAdmin = user.isAdmin || isAdminOfGroup(user, group);
  if (!isUser && !isAdmin) {
    return [];
  }
  const visibleSegments = group.hidden_custom_segments?.filter(
    (segment) => segment.privacy_settings?.viewable_by_self,
  );
  return isUser && !isAdmin ? visibleSegments : group.hidden_custom_segments;
}

export function getSelectionsForSegment(
  segmentSelections: AccountSegmentSelection[],
  customSegment: GroupCustomSegmentTypes,
) {
  const segmentSelection = getSegmentationSelection(
    segmentSelections,
    customSegment,
  );
  if ('multi' === customSegment.selection_type) {
    return get(segmentSelection, 'multiselected_nodes', []);
  }
  return get(segmentSelection, 'node', '');
}
