import {
  NOTIFICATIONS_LOADED_TYPE,
  NOTIFICATIONS_PATCH_TYPE,
  NOTIFICATIONS_SEEN_ALL_TYPE,
  NotificationsAction,
} from './notifications.actions';

import { INotificationState } from '../app.state';

import findIndex from 'lodash-es/findIndex';
import isEqual from 'lodash-es/isEqual';
import set from 'lodash-es/set';

const INITIAL_STATE: INotificationState = {
  isLoaded: false,
  items: [],
};

export function notificationsReducer(
  state: INotificationState = INITIAL_STATE,
  action: NotificationsAction,
) {
  switch (action.type) {
    case NOTIFICATIONS_LOADED_TYPE: {
      return {
        ...state,
        isLoaded: true,
        items: action.payload,
      };
    }

    case NOTIFICATIONS_PATCH_TYPE: {
      const itemsCopy = [...state.items];
      const indexToUpdate = findIndex(itemsCopy, (item) =>
        isEqual(item._id, action.payload._id),
      );
      set(itemsCopy, indexToUpdate, action.payload);
      return {
        ...state,
        items: itemsCopy,
      };
    }

    case NOTIFICATIONS_SEEN_ALL_TYPE: {
      return {
        ...state,
        items: state.items.map((item) => ({ ...item, seen: true })),
      };
    }

    default: {
      return state;
    }
  }
}
