import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Account } from '@libs/src/models/account.model';

import set from 'lodash-es/set';

const AUTOCOMPLETE_API_URLS = {
  admin: '/api/admin/accounts',
  group: (groupId: string) => `/api/v2/groups/${groupId}/accounts`,
};

interface MembersAutoCompleteResponse {
  items?: any[];
  accounts?: any[];
  count: number;
}

interface AdminAccountsSearchResponse extends MembersAutoCompleteResponse {
  data?: {
    count: number;
    results?: Account[];
  };
}

@Injectable()
export class AccountAutoCompleteService {
  constructor(public http: HttpClient) {}

  getAutoComplete(
    keyword: string,
    groupId?: string,
    status?: string,
  ): Observable<any> {
    if (!keyword) {
      return of([]);
    }

    const url = groupId
      ? AUTOCOMPLETE_API_URLS.group(groupId)
      : AUTOCOMPLETE_API_URLS.admin;
    type HttpGetOptions = NonNullable<Parameters<HttpClient['get']>[1]>;
    const params: HttpGetOptions['params'] = groupId
      ? {
          email: keyword,
          full_name: keyword,
          slug: keyword,
        }
      : { keyword };
    if (status) {
      set(params, 'status', status);
    }
    return this.http.get(url, { params }).pipe(
      map((response: AdminAccountsSearchResponse) => {
        return (
          response.items ||
          response.accounts ||
          (response.data && response.data.results)
        );
      }),
    );
  }
}
