import { Routes } from '@angular/router';

import { OnboardingV1Guard } from './onboarding-version-redirector/onboarding-v1-guard.service';
import { OnboardingV2Guard } from './onboarding-version-redirector/onboarding-v2-guard.service';
import { AdminLegacyGuardService } from '@main-client/src/app/admin/admin-legacy-guard.service';
import { AuthGuardService } from '@main-client/src/app/core/auth-guard.service';
import { CanLoadConversationsModuleGuardService } from '@main-client/src/app/core/can-load-conversations-module-guard.service';
import { TenantAdminGuardService } from '@main-client/src/app/core/tenant-admin-guard.service';

import { EmptyComponent } from '@main-client/src/app/empty/empty.component';

import { ActiveUserGuard } from '@main-client/src/app/core/active-user.guard';
import { HasGroupsGuard } from '@main-client/src/app/core/has-groups.guard';
import { NoAuthGuard } from '@main-client/src/app/core/no-auth.guard';
import { NonRootGuard } from '@main-client/src/app/core/non-root.guard';
import { GroupMembershipSettingsGuard } from '@main-client/src/app/groups/group-membership-settings.guard';
import { GroupsIntroOptOutGuard } from '@main-client/src/app/groups/groups-intro-opt-out.guard';

export const appRoutes: Routes = [
  {
    data: {
      hideHeader: true,
    },
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
    path: '',
    title: $localize`:@@appRoute.login:Log In`,
  },
  {
    data: {
      hideHeader: true,
    },
    loadChildren: () =>
      import('./groups/groups.module').then((m) => m.GroupsModule),
    path: 'hub',
  },
  {
    data: {
      hideHeader: true,
    },
    loadChildren: () =>
      import('./unsubscribe/unsubscribe.module').then(
        (m) => m.UnsubscribeModule,
      ),
    path: 'unsubscribe',
    title: $localize`:@@appRoute.unsubscribe:Unsubscribe`,
  },
  {
    data: {
      hideHeader: true,
    },
    loadChildren: () =>
      import('./global-unsubscribe-view/global-unsubscribe-view.module').then(
        (m) => m.GlobalUnsubscribeViewModule,
      ),
    path: 'global-unsubscribe',
    title: $localize`:@@appRoute.unsubscribe:Unsubscribe`,
  },
  {
    loadChildren: () =>
      import('./invite-members/invitation.module').then(
        (m) => m.InvitationModule,
      ),
    path: 'invitation',
  },
  {
    data: { hideHeader: true },
    loadChildren: () =>
      import(
        './group-program-overview-view/group-program-overview-view.module'
      ).then((m) => m.GroupProgramOverviewViewModule),
    path: 'program-overview',
  },
  {
    data: { hideHeader: true },
    loadChildren: () =>
      import(
        './pathway-invitation-landing-page/pathway-invitation-landing-page.module'
      ).then((m) => m.PathwayInvitationLandingPageModule),
    path: 'pathway-overview',
  },
  {
    data: { hideHeader: true },
    loadChildren: () =>
      import('./honey-badger-view/honey-badger-view.module').then(
        (m) => m.HoneyBadgerViewModule,
      ),
    path: 'welcome-to-10kc',
  },
  {
    loadChildren: () =>
      import('./add-to-calendar-view/add-to-calendar-view.module').then(
        (m) => m.AddToCalendarViewModule,
      ),
    path: 'add-to-calendar',
    title: $localize`:@@global.addToCalendar:Add to calendar`,
  },
  {
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'community-template',
        redirectTo: '/admin/get-started',
      },
      {
        canActivate: [OnboardingV2Guard],
        data: {
          hideHeader: true,
          hideHelp: true,
        },
        loadChildren: () =>
          import('./user-onboarding-v2/user-onboarding-v2.module').then(
            (m) => m.UserOnboardingV2Module,
          ),
        path: 'user-onboarding',
      },
      {
        canActivate: [OnboardingV1Guard],
        data: {
          hideHeader: true,
          hideHelp: true,
        },
        loadChildren: () =>
          import(
            './user-onboarding/user-onboarding-view/user-onboarding-view.module'
          ).then((m) => m.UserOnboardingViewModule),
        path: 'get-started',
      },
      {
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
        path: 'profile',
        title: $localize`:@@global.profile:Profile`,
      },
      {
        loadChildren: () =>
          import('./settings/settings-personal/settings-personal.module').then(
            (m) => m.SettingsPersonalModule,
          ),
        path: 'settings',
        title: $localize`:@@global.settings:Settings`,
      },
      {
        loadChildren: () =>
          import('./verify-email/verify-email.module').then(
            (m) => m.VerifyEmailModule,
          ),
        path: 'verify-email',
      },
      {
        data: {
          hideHeader: true,
        },
        loadChildren: () =>
          import('./verify-email-prompt/verify-email-prompt.module').then(
            (m) => m.VerifyEmailPromptModule,
          ),
        path: 'verify-email-prompt',
        title: $localize`:@@appRoute.verifyEmail:Verify your email`,
      },
      {
        canActivateChild: [ActiveUserGuard],
        children: [
          {
            canActivate: [HasGroupsGuard],
            loadChildren: () =>
              import('./feed/feed.module').then((m) => m.FeedModule),
            path: 'home',
            title: $localize`:@@appRoute.feed:Feed`,
          },
          {
            loadChildren: () =>
              import('./notifications/notifications.module').then(
                (m) => m.NotificationsModule,
              ),
            path: 'notifications',
            title: $localize`:@@appRoute.notifications:Notifications`,
          },
          {
            data: {
              hideHeader: true,
              showHomeButton: true,
            },
            loadChildren: () =>
              import('./group-intro-feedback/group-intro-feedback.module').then(
                (m) => m.GroupIntroFeedbackModule,
              ),
            path: 'introduction-feedback',
          },
          {
            data: {
              hideHeader: true,
            },
            loadChildren: () =>
              import(
                '@main-client/src/app/conversations-feedback/conversations-feedback.module'
              ).then((m) => m.ConversationsFeedbackModule),
            path: 'conversation-feedback',
          },
          {
            loadChildren: () =>
              import(
                './current-match-have-met-prompt-view/current-match-have-met-prompt-view.module'
              ).then((m) => m.CurrentMatchHaveMetPromptViewModule),
            path: 'introduction-have-met-prompt',
          },
          {
            loadChildren: () =>
              import('./hubs/hubs.module').then((m) => m.HubsModule),
            path: 'hubs',
            title: $localize`:@@appRoute.hubs:Hubs`,
          },
          {
            canLoad: [CanLoadConversationsModuleGuardService],
            data: {
              hideHelp: true,
            },
            loadChildren: () =>
              import('./conversations/conversations.module').then(
                (m) => m.ConversationsModule,
              ),
            path: 'conversations',
            title: $localize`:@@appRoute.conversations:Conversations`,
          },
          {
            loadChildren: () =>
              import('./create-group/create-group.module').then(
                (m) => m.CreateGroupModule,
              ),
            path: 'create-hub',
            title: $localize`:@@appRoute.createHub:Create Hub`,
          },
          {
            canActivate: [GroupsIntroOptOutGuard],
            component: EmptyComponent,
            path: 'opt-out',
          },
          {
            canActivate: [GroupMembershipSettingsGuard],
            component: EmptyComponent,
            path: 'membership-settings',
          },
          {
            canActivate: [TenantAdminGuardService],
            data: {
              hideHeader: true,
            },
            loadChildren: () =>
              import(
                './platform-template-editor/platform-template-editor.module'
              ).then((m) => m.PlatformTemplateEditorModule),
            path: 'template-editor',
          },
          {
            canActivate: [TenantAdminGuardService, AdminLegacyGuardService],
            loadChildren: () =>
              import(
                './settings/settings-tenant-view/settings-tenant-view.module'
              ).then((m) => m.SettingsTenantViewModule),
            path: 'admin',
          },
          {
            component: EmptyComponent,
            path: 'rsvps',
          },
          {
            loadChildren: () =>
              import('./opportunity/opportunity.module').then(
                (m) => m.OpportunityModule,
              ),
            path: 'opportunity',
          },
          {
            data: {
              hideHeader: true,
            },
            loadChildren: () =>
              import(
                './connection-feedback-view/connection-feedback-view.module'
              ).then((m) => m.ConnectionFeedbackViewModule),
            path: 'connection-feedback',
            title: $localize`:@@appRoute.connectionFeedback:Connection Feedback`,
          },
          {
            data: {
              hideHeader: true,
            },
            loadChildren: () =>
              import(
                './message-composer-view/message-composer-view.module'
              ).then((m) => m.MessageComposerViewModule),
            path: 'message-composer',
            title: $localize`:@@appRoute.messageComposer:Message Composer`,
          },
          {
            data: {
              hideHeader: true,
            },
            loadChildren: () =>
              import(
                './event-host-feedback-view/event-host-feedback-view.module'
              ).then((m) => m.EventHostFeedbackViewModule),
            path: 'event-host-feedback',
            title: $localize`:@@appRoute.officeHourHostFeedback:Office Hour Host Feedback`,
          },
          {
            data: {
              hideHeader: true,
            },
            loadChildren: () =>
              import(
                './event-host-prep-feedback-view/event-host-prep-feedback-view.module'
              ).then((m) => m.EventHostPrepFeedbackViewModule),
            path: 'event-host-prep-feedback',
            title: $localize`:@@appRoute.officeHourHostPrepFeedback:Office Hour host prep feedback`,
          },
          {
            data: {
              hideHeader: true,
            },
            loadChildren: () =>
              import(
                './event-participant-feedback-view/event-participant-feedback-view.module'
              ).then((m) => m.EventParticipantFeedbackViewModule),
            path: 'event-participant-feedback',
            title: $localize`:@@appRoute.officeHourFeedback:Office Hour feedback`,
          },
          {
            data: {
              hideHeader: false,
            },
            loadChildren: () =>
              import(
                './program-survey-user-view/program-survey-user-view.module'
              ).then((m) => m.ProgramSurveyUserViewModule),
            path: 'program-survey',
            title: $localize`:@@appRoute.programSurvey:Program survey`,
          },
          {
            data: {
              hideHeader: true,
            },
            loadChildren: () =>
              import(
                './group-update-account-goals/group-update-account-goals.module'
              ).then((m) => m.GroupUpdateAccountGoalsModule),
            path: 'update-goals',
            title: $localize`:@@appRoute.updateGoals:Update goals`,
          },
          {
            loadChildren: () =>
              import('./zendesk-signin/zendesk-signin.module').then(
                (m) => m.ZendeskSigninModule,
              ),
            path: 'help-center-signin',
            title: $localize`:@@appRoute.helpCenterSignin:Sign in to Help Center`,
          },
        ],
        path: '',
      },
    ],
    path: '',
  },
  {
    canActivate: [NoAuthGuard],
    children: [
      {
        data: {
          hideHeader: true,
        },
        loadChildren: () =>
          import(
            './delete-account-feedback/delete-account-feedback.module'
          ).then((m) => m.DeleteAccountFeedbackModule),
        path: 'delete-feedback',
        title: $localize`:@@appRoute.deleteFeedback:Delete feedback`,
      },
      {
        data: {
          hideHeader: true,
        },
        loadChildren: () =>
          import('./sign-up/sign-up.module').then((m) => m.SignUpModule),
        path: 'signup',
        title: $localize`:@@appRoute.signUp:Sign up`,
      },
      {
        canActivate: [NonRootGuard],
        data: {
          hideHeader: true,
        },
        loadChildren: () =>
          import('./signin-view/signin-view.module').then(
            (m) => m.SigninViewModule,
          ),
        path: 'signin',
        title: $localize`:@@appRoute.signIn:Sign in`,
      },
    ],
    path: '',
  },
  {
    data: {
      hideHeader: true,
    },
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
    path: 'login',
    title: $localize`:@@appRoute.login:Log in`,
  },
  {
    data: {
      hideHeader: true,
    },
    loadChildren: () =>
      import('./create-tenant/create-tenant.module').then(
        (m) => m.CreateTenantModule,
      ),
    path: 'create',
  },
  {
    data: {
      hideHeader: true,
    },
    loadChildren: () =>
      import(
        './master-service-agreement-view/master-service-agreement-view.module'
      ).then((m) => m.MasterServiceAgreementViewModule),
    path: 'master-service-agreement',
  },
  {
    loadChildren: () =>
      import('./join-event-rsvp-view/join-event-rsvp-view.module').then(
        (m) => m.JoinEventRsvpViewModule,
      ),
    path: 'rsvp',
    title: $localize`:@@global.officeHours:Office Hours`,
  },
  {
    data: { isEmptyState: true },
    loadChildren: () =>
      import('./not-found/not-found.module').then((m) => m.NotFoundModule),
    path: '**',
    title: $localize`:@@appRoute.pageNotFound:Page Not Found`,
  },
];
