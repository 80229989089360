import { Component, Input } from '@angular/core';

import { AvatarSizes } from '@libs/src/avatar/avatar.types';
import { Account } from '@libs/src/models/account.model';

@Component({
  selector: 'app-avatar-account',
  templateUrl: './avatar-account.component.html',
})
export class AvatarAccountComponent {
  @Input() item:
    | Pick<Account, 'first_name' | 'full_name' | 'portrait_original_url'>
    | undefined;
  @Input() size: AvatarSizes;
  @Input() showCamera: boolean;
}
