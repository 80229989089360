import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FlexModule } from '@ngbracket/ngx-layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

import { ProgressSpinnerModule } from '@main-client/src/app/progress-spinner/progress-spinner.module';

import { ContentLocalizationService } from '@main-client/src/app/translate/content-localization.service';

import { TranslatePageToggleComponent } from '@main-client/src/app/translate/translate-page-toggle.component';

@NgModule({
  declarations: [TranslatePageToggleComponent],
  exports: [TranslatePageToggleComponent],
  imports: [
    CommonModule,
    FlexModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    NzIconModule,
    NzSwitchModule,
    ProgressSpinnerModule,
  ],
  providers: [ContentLocalizationService],
})
export class TranslateModule {}
