import { Component, computed, Input, OnInit } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';

import { DestroySubscriptionsService } from '@libs/src/destroy-subscription/destroy-subscription.service';
import { TranslateService } from '@main-client/src/app/translate/translate.service';

import { fadeInGrowEnter } from '@libs/src/animations/animations';
import { getLocaleDisplayName } from '@libs/src/locale/locale.helper';

import { LocaleManagerModalService } from '../locale-manager/locale-manager-modal.service';

@Component({
  animations: [fadeInGrowEnter],
  providers: [DestroySubscriptionsService],
  selector: 'app-translate-page-toggle',
  templateUrl: './translate-page-toggle.component.html',
})
export class TranslatePageToggleComponent implements OnInit {
  @Input() isLoading: boolean;
  translateToggleSwitch = true;
  userLocaleName: string;
  $doesPageHaveAutoTranslations = computed(() =>
    this.translateService
      .$liveTranslationsInView()
      .some((item) => item.source !== item.target && item.auto_translated),
  );

  constructor(
    private readonly destroy$: DestroySubscriptionsService,
    private readonly localeManagerModalService: LocaleManagerModalService,
    private readonly translateService: TranslateService,
  ) {}

  isTranslating(): boolean {
    return 0 < this.translateService.$translationsInProgress();
  }

  ngOnInit(): void {
    this.userLocaleName = getLocaleDisplayName(
      this.translateService.getCurrentUserLocale(),
    );
    this.localeManagerModalService
      .listenToLiveTranslatePreference()
      .pipe(
        tap((liveTranslatePreference) => {
          this.translateToggleSwitch = liveTranslatePreference;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  toggleTranslation(switchValue: boolean) {
    this.localeManagerModalService.setLiveTranslatePreference(switchValue);
  }
}
