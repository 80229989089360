import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';

import { AuthService } from './auth.service';
import { AuthBackend } from '@libs/src/auth/auth.backend';

import { provideJwtOptions } from './jwt-options';
import { RequestInterceptor } from './request.interceptor';

@NgModule({
  imports: [HttpClientModule, JwtModule.forRoot({})],
  providers: [
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
    },
    provideJwtOptions(),
    AuthBackend,
    AuthService,
  ],
})
export class AuthModule {}
