import jwtDecode from 'jwt-decode';
import type { JwtPayload } from 'jwt-decode';

export function decodeJwt<T extends object>(jwt: string) {
  let decoded;
  try {
    decoded = jwtDecode<JwtPayload & T>(jwt);
  } catch (e) {
    return;
  }
  return decoded;
}
