import { Action } from '@ngrx/store';

export const SIDENAV_HIDE_TYPE = '[sidenav] hide';
export const SIDENAV_SHOW_TYPE = '[sidenav] show';

export class SideNavAction implements Action {
  constructor(public type = '') {}
}

export function HideSideNavigation(): SideNavAction {
  return new SideNavAction(SIDENAV_HIDE_TYPE);
}

export function ShowSideNavigation(): SideNavAction {
  return new SideNavAction(SIDENAV_SHOW_TYPE);
}
