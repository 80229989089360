import {
  FEED_INIT_TYPE,
  FEED_MATCH_CONFIG_UPDATE,
  FEED_POPULATE_TYPE,
  FEED_REMOVE_GROUP_TYPE,
  FeedAction,
  FeedUpdate,
} from './feed.actions';

import { FeedGroupState } from '../app.state';

import cloneDeep from 'lodash-es/cloneDeep';
import findIndex from 'lodash-es/findIndex';
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import unset from 'lodash-es/unset';

const INITIAL_STATE: FeedGroupState = {
  currentMatch: {},
  currentMatchFeedbackSubmissions: {},
  isCurrentMatchLoading: false,
  isInitialized: false,
  isLoading: false,
  isPastMatchesLoading: false,
  feedbacks: [],
  matches: [],
  matchConfigs: {
    items: [],
    count: 0,
  },
  matchCycles: {
    items: [],
    count: 0,
  },
  matchCyclePreview: {},
};

export function feedReducer(
  state: { [groupId: string]: FeedGroupState } = {},
  action: FeedAction,
) {
  switch (action.type) {
    case FEED_INIT_TYPE: {
      const {
        update,
        group: { _id: groupId },
      } = action.payload;
      return {
        ...state,
        [groupId]: {
          ...INITIAL_STATE,
          ...state[groupId],
          isInitialized: true,
          isLoading: false,
          matchConfigs: update.matchConfigs || INITIAL_STATE.matchConfigs,
          matchCycles: update.matchCycles || INITIAL_STATE.matchCycles,
          matchCyclePreview:
            update.matchCyclePreview || INITIAL_STATE.matchCyclePreview,
        },
      };
    }

    case FEED_POPULATE_TYPE: {
      const {
        group: { _id: groupId },
      } = action.payload;
      return {
        ...state,
        [groupId]: {
          ...INITIAL_STATE,
          ...state[groupId],
          isCurrentMatchLoading: true,
          isLoading: true,
          isPastMatchesLoading: true,
        },
      };
    }

    case FeedUpdate().type: {
      const {
        update,
        group: { _id: groupId },
      } = action.payload;
      return {
        ...state,
        [groupId]: {
          ...INITIAL_STATE,
          ...state[groupId],
          ...update,
        },
      };
    }

    case FEED_MATCH_CONFIG_UPDATE: {
      const { groupId, update } = action.payload;
      const currentMatchConfigIndex = findIndex(
        get(state, [groupId, 'matchConfigs', 'items'], []),
        (matchConfig) => update._id === matchConfig._id,
      );
      if (0 > currentMatchConfigIndex) {
        return state;
      }
      const stateClone = cloneDeep(state);
      return set(
        stateClone,
        [groupId, 'matchConfigs', 'items', currentMatchConfigIndex],
        update,
      );
    }

    case FEED_REMOVE_GROUP_TYPE: {
      const stateClone = { ...state };
      unset(stateClone, action.payload.group._id);
      return stateClone;
    }

    default: {
      return state;
    }
  }
}
