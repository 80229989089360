@if ($doesPageHaveAutoTranslations()) {
  <div
    [@fadeInGrowEnter]
    class="u-border-grey100 bg-color-white u-padding-16 u-marginBottom-16"
  >
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="u-marginRight-8">translate</mat-icon>
        <ng-container i18n="@@translatePageToggle.translatePrompt">
          Automatically translate applicable content to
          {{ userLocaleName }}</ng-container
        >
      </div>
      @if (isTranslating()) {
        <app-progress-spinner
          class="u-display-block u-marginLeft-16"
        ></app-progress-spinner>
      } @else {
        <nz-switch
          [nzCheckedChildren]="checkedTemplate"
          [nzDisabled]="isLoading"
          [nzUnCheckedChildren]="unCheckedTemplate"
          [(ngModel)]="translateToggleSwitch"
          (ngModelChange)="toggleTranslation(translateToggleSwitch)"
          class="u-marginLeft-16"
        ></nz-switch>
        <ng-template #checkedTemplate
          ><span nz-icon nzType="check"></span
        ></ng-template>
        <ng-template #unCheckedTemplate
          ><span nz-icon nzType="close"></span
        ></ng-template>
      }
    </div>
  </div>
}
