import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { filter, take } from 'rxjs/operators';

import { IAppState } from '../app.state';
import {
  HideSideNavigation,
  ShowSideNavigation,
} from '../groups/group.actions';
import { HideHeader, ShowHeader } from '../header/header.actions';

import isEqual from 'lodash-es/isEqual';

@Injectable()
export class RouteListenerService {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store<IAppState>,
  ) {}

  init() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.toggleHeaderVisibility();
        this.toggleSideNavVisibility();
      });
  }

  toggleHeaderVisibility(): void {
    this.toggleVisibility('header', 'hideHeader', ShowHeader, HideHeader);
  }

  toggleSideNavVisibility(): void {
    this.toggleVisibility(
      'sideNav',
      'hideSideNavigation',
      ShowSideNavigation,
      HideSideNavigation,
    );
  }

  toggleVisibility<T extends string>(
    storeKey: keyof IAppState,
    dataKey: string,
    ...[showAction, hideAction]: Array<() => TypedAction<T>>
  ): void {
    this.store
      .select(storeKey)
      .pipe(take(1))
      .subscribe((keyState: any) => {
        const showElement = !this.findDataValue(this.route.snapshot, dataKey);
        if (isEqual(showElement, keyState.show)) {
          return;
        }
        const elementAction = showElement ? showAction() : hideAction();
        this.store.dispatch(elementAction);
      });
  }

  findDataValue(
    routeSnapshot: ActivatedRouteSnapshot,
    dataKey: string,
  ): boolean | string {
    const dataValue = routeSnapshot.data[dataKey];
    if (dataValue) {
      return dataValue;
    }

    const firstChildSnapshot = routeSnapshot.firstChild;
    return (
      firstChildSnapshot && this.findDataValue(firstChildSnapshot, dataKey)
    );
  }
}
