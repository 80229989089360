export enum EVENT_SUBMISSIONS_ACTION_TYPES {
  add = 'add',
  confirm = 'confirm',
  reinvite = 'reinvite',
  remove = 'remove',
  removeSelected = 'removeSelected',
}

export enum PARTICIPANT_STATUSES {
  registered = 'registered',
  cancelled = 'cancelled',
  host = 'host',
  removed = 'removed',
  error = 'error',
}

export interface EventSubmissionsDialogInput {
  actionTitle?: string;
  attendeesCount: number;
  surplusSubmissions?: number;
  totalSubmissions?: number;
  type: EVENT_SUBMISSIONS_ACTION_TYPES;
}

export interface EventSubmissionsNotification {
  message: string;
  sendNotification: boolean;
}
