import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HubsEffectsService } from './hubs-effects.service';

import { hubsReducer } from './hubs.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([HubsEffectsService]),
    StoreModule.forFeature('hubs', hubsReducer),
  ],
  providers: [HubsEffectsService],
})
export class HubsStoreModule {}
