import { Injectable, TemplateRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject } from 'rxjs';

import { LocaleManagerComponent } from './locale-manager.component';

import { Account } from '@libs/src/models/account.model';

@Injectable({
  providedIn: 'root',
})
export class LocaleManagerModalService {
  private readonly liveTranslatePreference$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  constructor(private readonly modalService: NzModalService) {}

  openLanguageManager(user: Account) {
    this.modalService.create({
      nzClosable: true,
      nzContent: LocaleManagerComponent,
      nzData: { user },
      nzFooter: null,
    });
  }

  setLiveTranslatePreference(toggleValue: boolean) {
    this.liveTranslatePreference$.next(toggleValue);
  }

  listenToLiveTranslatePreference() {
    return this.liveTranslatePreference$.asObservable();
  }
}
