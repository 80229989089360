import { HttpErrorResponse } from '@angular/common/http';
import { Component, computed, Inject, OnInit, Signal } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { EMPTY } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { AccountBackend } from '@libs/src/account/account.backend';
import { DestroySubscriptionsService } from '@libs/src/destroy-subscription/destroy-subscription.service';
import { ToastService } from '@libs/src/toast/toast.service';
import { LocaleSettingsService } from '@main-client/src/app/core/locale-settings.service';
import { LocaleManagerService } from '@main-client/src/app/locale-manager/locale-manager.service';
import { WindowRefService } from '@main-client/src/app/window-ref.service';

import { getServerError } from '@libs/src/error-handler/error-handler.helper';
import { defaultLocale, locales } from '@libs/src/locale/locale.helper';
import { Locale } from '@libs/src/locale/locale.interface';
import { Account } from '@libs/src/models/account.model';

@Component({
  providers: [DestroySubscriptionsService],
  selector: 'app-locale-manager',
  templateUrl: './locale-manager.component.html',
})
export class LocaleManagerComponent implements OnInit {
  allowedLocaleNames$: Signal<string[]> = computed(() =>
    Object.keys(this.allowedLocales).map(
      (localeCode) => this.allowedLocales[localeCode].translatedName,
    ),
  );
  $isCurrentLocaleAllowed: Signal<boolean> = computed(
    () => !!this.allowedLocales[this.currentUserLocale.code],
  );
  localesInSelector$: Signal<{ [key: string]: Locale }> = computed(() => ({
    ...this.allowedLocales,
    [this.currentUserLocale.code]: this.currentUserLocale,
  }));
  allowedLocales: { [key: string]: Locale } = {};
  currentUserLocale: Locale;
  isLoading = false;
  user: Account;

  constructor(
    @Inject(NZ_MODAL_DATA) private readonly data: { user: Account },
    private readonly accountBackend: AccountBackend,
    private readonly destroy$: DestroySubscriptionsService,
    private readonly localeManagerService: LocaleManagerService,
    private readonly localeSettingsService: LocaleSettingsService,
    private readonly toastService: ToastService,
    private readonly windowRefService: WindowRefService,
  ) {}

  ngOnInit(): void {
    this.user = this.data?.user;
    this.currentUserLocale = locales.find(
      (locale) =>
        locale.code === this.localeManagerService.getLocaleForUser(this.user),
    );
    this.initLocalesForView(this.user);
  }

  switchLocale(selectedLocale: Locale) {
    this.isLoading = true;
    this.accountBackend
      .patchAccount({
        locale_config: this.localeSettingsService.generateLocaleConfig(
          selectedLocale.code,
        ),
      })
      .pipe(
        tap(() => {
          this.localeSettingsService.setLocaleCookie(selectedLocale.code);
          this.reloadPage();
        }),
        catchError((error: HttpErrorResponse) => {
          this.toastService.showError(getServerError(error));
          this.isLoading = false;
          return EMPTY;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  reloadPage() {
    this.windowRefService.nativeWindow.location.reload();
  }

  initLocalesForView(user: Account) {
    const groupMemberships = user.groups || [];
    let areSupportedLocalesSet = false;
    let commonGroupLocales = [...locales];
    for (const membership of groupMemberships) {
      const group = membership.document;
      if (false === group?.features_enabled?.localization) {
        this.setDefaultAllowedLocales();
        return;
      }
      if (group?.supported_locales?.length) {
        areSupportedLocalesSet = true;
        commonGroupLocales = commonGroupLocales.filter((locale) =>
          group?.supported_locales.includes(locale.code),
        );
      }
    }
    if (!areSupportedLocalesSet) {
      this.setAllowedLocales(locales);
    } else {
      this.setAllowedLocales(commonGroupLocales);
    }
  }

  setDefaultAllowedLocales() {
    this.allowedLocales = { [defaultLocale.code]: defaultLocale };
  }

  setAllowedLocales(localesToAllow: Locale[]) {
    this.allowedLocales = localesToAllow.reduce(
      (acc, locale) => {
        acc[locale.code] = locale;
        return acc;
      },
      {} as { [key: string]: Locale },
    );
  }
}
