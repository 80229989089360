import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

type SpinnerSize = 'large' | 'regular' | 'small' | 'xlarge';

interface ProgressSpinnerConfig {
  diameter: number;
  strokeWidth: number;
}

const enum SIZE_NAMES {
  xlarge = 'xlarge',
  large = 'large',
  regular = 'regular',
  small = 'small',
}

const SIZES_CONFIG: Record<SpinnerSize, ProgressSpinnerConfig> = {
  [SIZE_NAMES.large]: {
    diameter: 48,
    strokeWidth: 4,
  },
  [SIZE_NAMES.regular]: {
    diameter: 24,
    strokeWidth: 2,
  },
  [SIZE_NAMES.small]: {
    diameter: 16,
    strokeWidth: 2,
  },
  [SIZE_NAMES.xlarge]: {
    diameter: 96,
    strokeWidth: 8,
  },
};
const DEFAULT_SIZE = SIZES_CONFIG[SIZE_NAMES.regular];

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
})
export class ProgressSpinnerComponent implements OnChanges {
  @Input() color: string;
  @Input() mode: string;
  @Input() size: SpinnerSize;
  @Input() value: number;
  selectedSize: ProgressSpinnerConfig = DEFAULT_SIZE;

  ngOnChanges() {
    this.selectedSize = SIZES_CONFIG[this.size] || DEFAULT_SIZE;
  }
}
