import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

import { SnackBarData } from '@libs/src/models/custom-toast.model';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
})
export class CustomToastComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData,
    public readonly snackBarRef: MatSnackBarRef<CustomToastComponent>,
  ) {}
}
