import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, filter, finalize, map, tap } from 'rxjs/operators';

import { LoggerService } from '@libs/src/logger/logger.service';

import { SetTenant } from './tenant.actions';
import { getServerError } from '@libs/src/error-handler/error-handler.helper';
import { Tenant } from '@libs/src/tenant/tenant.interface';

import { IAppState } from '../app.state';

import {
  LocalStorageService,
  TENANT_TOKEN_KEY,
} from '../core/local-storage.service';

import isEmpty from 'lodash-es/isEmpty';

const TENANT_API_URL = '/api/v2/tenant';

@Injectable({ providedIn: 'root' })
export class TenantService {
  private readonly isTenantLoading = new BehaviorSubject(false);
  isInvalidTenantRequest = false;
  isTenantLoading$ = this.isTenantLoading.asObservable();

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private loggerService: LoggerService,
    private store: Store<IAppState>,
  ) {}

  populate() {
    this.isTenantLoading.next(true);
    const localStorageTenantToken = this.localStorage.getItem(TENANT_TOKEN_KEY);
    if (localStorageTenantToken) {
      const tenant: Tenant = jwtDecode(localStorageTenantToken);
      this.store.dispatch(SetTenant(tenant));
    }
    this.http
      .get(TENANT_API_URL)
      .pipe(
        map(({ tenantToken }: { tenantToken: string }) => {
          const tenant: Tenant = jwtDecode(tenantToken);
          return { tenantToken, tenant };
        }),
        filter((result) => !isEmpty(result)),
        tap(({ tenant, tenantToken }) => {
          try {
            this.localStorage.setItem(TENANT_TOKEN_KEY, tenantToken);
          } catch (error) {
            this.loggerService.logError({
              message: `Attempting to set invalid tenant information (response token: ${tenantToken}; tenant: ${tenant})`,
            });
          }
        }),
        tap(({ tenant }) => this.store.dispatch(SetTenant(tenant))),
        catchError((response: HttpErrorResponse) => {
          this.isInvalidTenantRequest = true;
          const errorMessage = getServerError(response);
          this.loggerService.logError({
            message: `Error setting tenant. Message: ${errorMessage}`,
          });
          return EMPTY;
        }),
        finalize(() => {
          this.isTenantLoading.next(false);
        }),
      )
      .subscribe();
  }
}
