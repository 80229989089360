import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { AuthService } from '@main-client/src/app/auth/auth.service';

import { Account } from '@libs/src/models/account.model';
import { IAppState } from '@main-client/src/app/app.state';

import isEmpty from 'lodash-es/isEmpty';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly store: Store<IAppState>,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    if (this.authService.$isLoggedIn()) {
      return this.store.select('user').pipe(
        filter((user: Account) => !isEmpty(user)),
        take(1),
        map(() => true),
      );
    }
    const urlTree = this.router.createUrlTree(['/login'], {
      queryParams: { redirectUrl: state.url },
    });
    return of(urlTree);
  }
}
