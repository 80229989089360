import { Action } from '@ngrx/store';

export const HEADER_HIDE_TYPE = '[header] hide';
export const HEADER_RESET_UNSEEN_CONVERSATIONS_COUNT_TYPE =
  '[header] reset unseen conversations count';
export const HEADER_RESET_UNSEEN_NOTIFICATIONS_COUNT_TYPE =
  '[header] reset unseen notifications count';
export const HEADER_SET_UNSEEN_CONVERSATIONS_COUNT_TYPE =
  '[header] set unseen conversations count';
export const HEADER_SET_UNSEEN_NOTIFICATIONS_COUNT_TYPE =
  '[header] set unseen notifications count';
export const HEADER_SHOW_TYPE = '[header] show';

export class HeaderAction implements Action {
  constructor(
    public type = '',
    public payload = {},
  ) {}
}

export function HideHeader(): HeaderAction {
  return new HeaderAction(HEADER_HIDE_TYPE);
}

export function ResetUnseenConversationsCount(): HeaderAction {
  return new HeaderAction(HEADER_RESET_UNSEEN_CONVERSATIONS_COUNT_TYPE, 0);
}

export function ResetUnseenNotificationsCount(): HeaderAction {
  return new HeaderAction(HEADER_RESET_UNSEEN_NOTIFICATIONS_COUNT_TYPE, 0);
}

export function SetUnseenConversationsCount(payload = 0): HeaderAction {
  return new HeaderAction(HEADER_SET_UNSEEN_CONVERSATIONS_COUNT_TYPE, payload);
}

export function SetUnseenNotificationsCount(payload = 0): HeaderAction {
  return new HeaderAction(HEADER_SET_UNSEEN_NOTIFICATIONS_COUNT_TYPE, payload);
}

export function ShowHeader(): HeaderAction {
  return new HeaderAction(HEADER_SHOW_TYPE);
}
