import { Action } from '@ngrx/store';

export const SET_CURRENT_GROUP_TYPE = '[current group] set group';

export class CurrentGroupAction implements Action {
  payload: string;
  type: string;

  constructor(type: string, payload: string) {
    this.payload = payload;
    this.type = type;
  }
}

export function SetCurrentGroup(groupId: string) {
  return new CurrentGroupAction(SET_CURRENT_GROUP_TYPE, groupId);
}
