<div mat-dialog-title i18n="@@userIdleTimeoutDialog.title">
  Are you still there?
</div>
<div mat-dialog-content>
  <p i18n="@@userIdleTimeoutDialog.message">
    For security reasons, we will log you out in
    {{ countdown | async }} seconds. Do you want to stay signed in?
  </p>
</div>
<mat-dialog-actions
  class="u-display-block"
  fxLayout="row"
  fxLayoutAlign="end center"
>
  <button
    mat-button
    mat-dialog-close
    type="button"
    i18n="@@userIdleTimeoutDialog.logMeOut"
  >
    Log me out
  </button>
  <button
    color="primary"
    mat-button
    mat-dialog-close="true"
    type="button"
    i18n="@@userIdleTimeoutDialog.stayLoggedIn"
  >
    Stay logged in
  </button>
</mat-dialog-actions>
