export const LOCALE_EN_US = 'en-US';
export const LOCALE_ES = 'es';
export const LOCALE_FR_CA = 'fr-CA';
export const LOCALE_JA = 'ja';
export const LOCALE_KO = 'ko';
export const LOCALE_CODES = Object.freeze({
  enUS: 'en-US',
  es: 'es',
  frCA: 'fr-CA',
  ja: 'ja',
  ko: 'ko',
} as const);
export const DEFAULT_LOCALE_CODE = LOCALE_CODES.enUS;
export const SUPPORTED_LOCALES = Object.freeze([
  LOCALE_EN_US,
  LOCALE_ES,
  LOCALE_FR_CA,
  LOCALE_JA,
  LOCALE_KO,
] as const);
export type SupportedLocaleCode = (typeof SUPPORTED_LOCALES)[number];
export const NON_DEFAULT_LOCALE_CODES = SUPPORTED_LOCALES.filter(
  (localeCode) => localeCode !== DEFAULT_LOCALE_CODE,
);

export const LANGUAGE_CODE_EN = 'en';
export const LANGUAGE_LOCALE_CODE_MAPPINGS = Object.freeze({
  en: LOCALE_EN_US,
  es: LOCALE_ES,
  fr: LOCALE_FR_CA,
  ja: LOCALE_JA,
  ko: LOCALE_KO,
});
