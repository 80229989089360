import {
  GroupCustomSegmentNode,
  GroupRole,
} from '@libs/src/models/group.model';
import { GroupCustomSegmentTypes } from '@libs/src/models/group.model';
import {
  MatchConfig,
  MatchConfigCustomSegmentConfig,
  MatchSegment,
} from '@libs/src/models/match.interfaces';

import findIndex from 'lodash-es/findIndex';
import includes from 'lodash-es/includes';
import isEqual from 'lodash-es/isEqual';
import isUndefined from 'lodash-es/isUndefined';
import sortBy from 'lodash-es/sortBy';
import uniqBy from 'lodash-es/uniqBy';

type MatchConfigEdges =
  | MatchConfig['custom_segment_configs'][number]['edges']
  | MatchConfig['hidden_custom_segment_configs'][number]['edges'];

export function generateEdgeUpdates(options: {
  targetNode: GroupCustomSegmentNode;
  currentEdges: string[][];
  updateEdges: string[][];
}) {
  const targetCurrentEdges = options.currentEdges.filter((edge: string[]) =>
    includes(edge, options.targetNode.name),
  );
  return {
    delete: targetCurrentEdges.filter((item) => {
      return options.updateEdges.every((updateEdge) => {
        return !isEqual(sortBy(updateEdge), sortBy(item));
      });
    }),
    put: options.updateEdges.filter((edge: string[]) => {
      return targetCurrentEdges.every((currentEdge: string[]) => {
        return !isEqual(sortBy(currentEdge), sortBy(edge));
      });
    }),
  };
}

export function generateNodesPerEdge(options: {
  edges: string[][];
  nodes: GroupCustomSegmentNode[];
}) {
  return options.nodes.reduce(
    (result, node) => {
      result[node.name] = options.edges
        .filter((edge: string[]) => edge?.includes(node.name))
        .map((edge: string[]) => {
          const targetIndex =
            0 === findIndex(edge, (item) => item === node.name) ? 1 : 0;
          return edge[targetIndex];
        })
        .map((nodeName: string) =>
          options.nodes.find((item: GroupCustomSegmentNode) =>
            isEqual(item.name, nodeName),
          ),
        )
        .filter((segmentNode) => !isUndefined(segmentNode));
      return result;
    },
    {} as { [nodeName: string]: GroupCustomSegmentNode[] },
  );
}

export function generateEdges(options: {
  node: GroupCustomSegmentNode;
  nodesToMakeEdgesWith: GroupCustomSegmentNode[];
}): MatchConfigCustomSegmentConfig['edges'] {
  return options.nodesToMakeEdgesWith.map((item) => {
    return [options.node.name, item.name];
  });
}
export function generateEdgesForMatchingAllOptions(
  segment: GroupCustomSegmentTypes,
): MatchConfigEdges {
  const edgesPerNode = (segment.nodes ?? []).map((node) => {
    return generateEdges({ node, nodesToMakeEdgesWith: segment.nodes });
  });
  return uniqBy(edgesPerNode.flat(), (edge) => edge.sort().toString());
}

export function generateEdgesForMatchingAllOtherOptions(
  segment: GroupCustomSegmentTypes,
) {
  const edgesPerNode = (segment.nodes ?? []).map((node) => {
    return generateEdges({
      node,
      nodesToMakeEdgesWith: (segment.nodes ?? []).filter(
        ({ name }) => name !== node.name,
      ),
    });
  });
  return uniqBy(edgesPerNode.flat(), (edge) => edge.sort().toString());
}

export function generateEdgesForMatchingSameOptions(
  segment: GroupCustomSegmentTypes,
): MatchConfigEdges {
  return (segment.nodes ?? []).flatMap((node) => {
    return generateEdges({ node, nodesToMakeEdgesWith: [node] });
  });
}

export function generateMatchAllOptionsEdgesForRoles(
  roles: GroupRole[],
): MatchSegment[] {
  return (roles ?? []).map((role) => {
    return {
      match_with: roles.map(({ name }) => name),
      path: 'groups.role',
      value: role.name,
    } satisfies MatchSegment;
  });
}

export function generateMatchAllOtherOptionsEdgesForRoles(
  roles: GroupRole[],
): MatchSegment[] {
  return (roles ?? []).map((role) => {
    return {
      match_with: roles
        .filter(({ name }) => name !== role.name)
        .map(({ name }) => name),
      path: 'groups.role',
      value: role.name,
    } satisfies MatchSegment;
  });
}

export function generateMatchSameOptionsEdgesForRoles(
  roles: GroupRole[],
): MatchSegment[] {
  return (roles ?? []).map((role) => {
    return {
      match_with: [role.name],
      path: 'groups.role',
      value: role.name,
    } satisfies MatchSegment;
  });
}
