import {
  CurrentGroupAction,
  SET_CURRENT_GROUP_TYPE,
} from '@main-client/src/app/current-group-store/current-group.actions';

const INITIAL_STATE = '';

export function currentGroupReducer(
  state: string = INITIAL_STATE,
  action: CurrentGroupAction,
) {
  switch (action.type) {
    case SET_CURRENT_GROUP_TYPE: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
}
