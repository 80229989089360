<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'determinate'">
    <mat-progress-spinner
      mode="determinate"
      [strokeWidth]="selectedSize.strokeWidth"
      [diameter]="selectedSize.diameter"
      [color]="color"
      [value]="value"
      class="u-margin-center"
    >
    </mat-progress-spinner>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <mat-progress-spinner
      mode="indeterminate"
      [strokeWidth]="selectedSize.strokeWidth"
      [diameter]="selectedSize.diameter"
      [color]="color"
      class="u-margin-center"
    >
    </mat-progress-spinner>
  </ng-container>
</ng-container>
