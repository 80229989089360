import { Validators } from '@angular/forms';

import { Account } from '@libs/src/models/account.model';
import { Group } from '@libs/src/models/group.model';

export const onboardingSuccessFeatureSeenIdentifier = 'onboarding-success';
export const USER_ONBOARDING_ROUTES_BASE_PATH = '/get-started';
export enum USER_ONBOARDING_ROUTE_PATHS {
  hello = 'hello',
  hubSegments = 'hub-segments',
  hubInteractions = 'hub-interactions',
  hubInterests = 'hub-interests',
  hubGoals = 'hub-goals',
  hubs = 'hubs',
  introGoals = 'intro-goals',
  profile = 'profile',
}

export enum FORM_TYPE {
  action = 'action',
  autocomplete = 'autocomplete',
  input = 'input',
}

export enum USER_KEYS {
  email = 'email',
  first_name = 'first_name',
  full_name = 'full_name',
  last_name = 'last_name',
  portrait_original_url = 'portrait_original_url',
}

export enum USER_GEO_LOCATION_KEYS {
  address = 'geo_location.0.address',
  location = 'geo_location.0.location',
}

const profileInputMaxLength = 100;

export const USER_ONBOARD_PROFILE_FORM = [
  {
    updateKey: USER_KEYS.portrait_original_url,
    hide: true,
  },
  {
    updateKey: USER_KEYS.email,
    disable: true,
    placeholder: 'Email',
    icon: 'check_circle',
    type: FORM_TYPE.input,
  },
  {
    updateKey: USER_KEYS.first_name,
    placeholder: 'First Name',
    validators: [Validators.required],
    type: FORM_TYPE.input,
  },
  {
    updateKey: USER_KEYS.last_name,
    placeholder: 'Last Name',
    validators: [Validators.required],
    type: FORM_TYPE.input,
  },
  {
    updateKey: 'experience.0.job_title',
    maxLength: profileInputMaxLength,
    placeholder: 'Job Title',
    type: FORM_TYPE.input,
  },
  {
    updateKey: 'experience.0.organization_name',
    maxLength: profileInputMaxLength,
    placeholder: 'Company Name',
    type: FORM_TYPE.input,
  },
];

export const SAVE_ACTION = {
  updateKey: 'save',
  color: 'primary',
  label: 'Next',
  raised: true,
  submit: true,
  type: FORM_TYPE.action,
};

export const BACK_ACTION = {
  updateKey: 'back',
  color: 'basic',
  label: 'Back',
  raised: false,
  submit: false,
  type: FORM_TYPE.action,
};

export interface AuthResponse {
  isNewAccount: boolean;
  profile: Account;
  refresh_token: string;
  token: string;
}

export function onboardingFeatureIdentifierForGroup(groupId: string) {
  return `${onboardingSuccessFeatureSeenIdentifier}-${groupId}`;
}

export function isAnySegmentSelectableForMatchingRules(group: Group) {
  const customSegmentSelectableOption = [
    ...(group?.custom_segments ?? []),
    ...(group?.hidden_custom_segments ?? []),
  ].map(
    ({ is_selectable_for_user_introduction_goals }) =>
      is_selectable_for_user_introduction_goals,
  );
  return [
    group.is_role_selectable_for_user_introduction_goals ?? true,
    ...customSegmentSelectableOption,
  ].some((isSelectable) => isSelectable);
}
