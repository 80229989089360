<div mat-dialog-title i18n="@@profileView.emailVerificationDialog.title">
  Confirm your email address
</div>
<mat-dialog-content>
  <div
    class="bg-color-teal-50 u-textAlign-center"
    fxLayout="row"
    fxLayoutAlign="center"
  >
    <img
      src="https://storage.googleapis.com/ttc-static-images/verify-email-visualization.EFAA746.png"
      alt="Email Verification Visualization"
    />
  </div>
  <p
    i18n="@@profileView.emailVerificationDialog.content"
    class="u-marginTop-24"
  >
    To start connecting with other members, please confirm your email address.
    Check your {{ data.user.email }} account.
  </p>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button
    i18n="@@global.gotIt"
    mat-button
    mat-dialog-close
    color="primary"
    type="button"
  >
    Got it
  </button>
</mat-dialog-actions>
