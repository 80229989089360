import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-route-loading-indicator',
  templateUrl: './route-loading-indicator.component.html',
  styleUrls: ['./route-loading-indicator.component.scss'],
})
export class RouteLoadingIndicatorComponent implements OnInit, OnDestroy {
  isRouterLoading = false;
  componentDestroy = new Subject<void>();

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events
      .pipe(takeUntil(this.componentDestroy))
      .subscribe((routerEvent: Event) => {
        if (routerEvent instanceof NavigationStart) {
          this.isRouterLoading = true;
          return;
        }

        if (
          routerEvent instanceof NavigationEnd ||
          routerEvent instanceof NavigationCancel ||
          routerEvent instanceof NavigationError
        ) {
          this.isRouterLoading = false;
        }
      });
  }

  ngOnDestroy() {
    this.componentDestroy.next();
    this.componentDestroy.complete();
  }
}
