import { Directive, HostListener } from '@angular/core';

import { ZendeskService } from '../zendesk/zendesk.service';

@Directive({
  selector: '[appZendeskLink]',
})
export class ZendeskLinkDirective {
  constructor(private readonly zendeskService: ZendeskService) {}

  @HostListener('click', ['$event.target'])
  click(element: HTMLAnchorElement | null) {
    if ('a' === element?.tagName.toLowerCase()) {
      const href = element.getAttribute('href');
      if (href && this.zendeskService.isHelpCenterUrl(href)) {
        this.zendeskService.navigate(href);
        return false;
      }
    }
  }
}
