import { Injectable } from '@angular/core';
import { filter, fromEvent, map, merge, Observable, of } from 'rxjs';

// `storage` events dont fire on the same tab/window where they happen, so
// we fire a custom event for our own window. Alternatively, we could use a
// subject and just `next` it.
const CUSTOM_EVENT_NAME = 'ttc_local_storage_change';
interface LocalStorageChange {
  key: string;
  newValue: string | null;
}

export interface Options {
  excludeSelf?: boolean;
  includeCurrent?: boolean;
}

export const AUTH_TOKEN_KEY = 'tenthousandcoffees_token';
export const REFRESH_TOKEN_KEY = 'ttc_refresh_token';
export const TENANT_TOKEN_KEY = 'ttc_tenant_token';
export const ACTIVE_SESSION_KEY = 'ttc_active_session';

declare let localStorage: Storage;

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  getItem(key: string) {
    return localStorage.getItem(key);
  }

  getItem$(key: string, { excludeSelf, includeCurrent }: Options = {}) {
    const sources: Observable<LocalStorageChange | StorageEvent>[] = [
      fromEvent<StorageEvent>(window, 'storage'),
    ];
    if (includeCurrent) {
      sources.unshift(of({ key, newValue: this.getItem(key) }));
    }
    if (!excludeSelf) {
      sources.push(
        fromEvent<CustomEvent<LocalStorageChange>>(
          window,
          CUSTOM_EVENT_NAME,
        ).pipe(map((event) => event.detail)),
      );
    }
    return merge(...sources).pipe(
      filter((event) => key === event.key),
      map((event) => event.newValue),
    );
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
    window.dispatchEvent(
      new CustomEvent<LocalStorageChange>(CUSTOM_EVENT_NAME, {
        detail: { key, newValue: null },
      }),
    );
  }

  setItem(key: string, newValue: string) {
    localStorage.setItem(key, newValue);
    window.dispatchEvent(
      new CustomEvent<LocalStorageChange>(CUSTOM_EVENT_NAME, {
        detail: { key, newValue },
      }),
    );
  }
}
