import { Action } from '@ngrx/store';

import { Group } from '@libs/src/models/group.model';
import { MatchConfig } from '@libs/src/models/match.interfaces';

export const FEED_INIT_TYPE = '[feed] Init';
export const FEED_POPULATE_TYPE = '[feed] Populate';
export const FEED_REMOVE_GROUP_TYPE = '[feed] Remove Group';
export const FEED_UPDATE_TYPE = '[feed] Update';
export const FEED_MATCH_CONFIG_UPDATE = '[feed] Match Config Update';

export class FeedAction implements Action {
  constructor(
    public type: string = '',
    public payload: any = {},
  ) {}
}

export function FeedPopulate(group: Group): FeedAction {
  return new FeedAction(FEED_POPULATE_TYPE, { group });
}

export function FeedInit(payload: { group: Group; update: any }): FeedAction {
  return new FeedAction(FEED_INIT_TYPE, payload);
}

export function FeedUpdate(payload?: {
  group: Group;
  update: any;
}): FeedAction {
  return new FeedAction(FEED_UPDATE_TYPE, payload);
}

export function FeedRemoveGroup(payload: { group: Group }) {
  return new FeedAction(FEED_REMOVE_GROUP_TYPE, payload);
}

export function FeedMatchConfigUpdate(payload: {
  groupId: string;
  update: MatchConfig;
}) {
  return new FeedAction(FEED_MATCH_CONFIG_UPDATE, payload);
}
