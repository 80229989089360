import { HttpClient } from '@angular/common/http';
import { computed, effect, Injectable, signal } from '@angular/core';
import type { WritableSignal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { concat, EMPTY, Observable, of, Subject, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  pairwise,
  switchMap,
  tap,
} from 'rxjs/operators';

import { AnalyticsService } from '@libs/src/analytics/analytics.service';
import type { SignUpData, SignUpParams } from '@libs/src/auth/auth.backend';
import { AuthBackend } from '@libs/src/auth/auth.backend';
import { LoggerService } from '@libs/src/logger/logger.service';
import { PostHogService } from '@libs/src/posthog/post-hog.service';
import {
  ACTIVE_SESSION_KEY,
  AUTH_TOKEN_KEY,
  LocalStorageService,
  REFRESH_TOKEN_KEY,
} from '@main-client/src/app/core/local-storage.service';

import type {
  LoginResponse,
  LogInTokenCredentials,
} from '@libs/src/interfaces/login-response.interface';
import type { AuthToken } from '@libs/src/models/auth-token-model';
import { decodeJwt } from '@libs/src/utilities/jwt.utilities';
import type { IAppState } from '@main-client/src/app/app.state';

import isEmpty from 'lodash-es/isEmpty';

const DEFAULT_LOGOUT_CALLBACK = () => {
  window.location.href = '/';
};
const LOGIN_API_URL = '/api/auth/login';
const REFRESH_TOKEN_URL = '/api/v2/auth/token';
const SESSION_API_URL = '/api/v2/auth/session';

export const TOKEN_STATUS_PENDING: null = null;
export const NO_TOKEN = false;

interface AuthTokenResponse {
  refresh_token?: string;
  token: string;
}

@Injectable()
export class AuthService {
  protected $authToken: WritableSignal<
    string | typeof NO_TOKEN | typeof TOKEN_STATUS_PENDING
  > = signal(TOKEN_STATUS_PENDING);
  protected authToken$ = toObservable(this.$authToken);
  protected $tenant = toSignal(
    this.store.select('tenant').pipe(filter((tenant) => !isEmpty(tenant))),
  );
  protected $shouldUseLocalStorage = computed(
    () => !this.$tenant()?.enabled_features?.auth_cookie,
  );
  protected $refreshToken: WritableSignal<
    string | typeof NO_TOKEN | typeof TOKEN_STATUS_PENDING
  > = signal(TOKEN_STATUS_PENDING);

  constructor(
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly logger: LoggerService,
    private readonly analyticsService: AnalyticsService,
    private readonly postHogService: PostHogService,
    private readonly authBackend: AuthBackend,
    private readonly store: Store<IAppState>,
  ) {
    if (TOKEN_STATUS_PENDING === this.$authToken()) {
      const storedAuthToken = this.localStorageService.getItem(AUTH_TOKEN_KEY);
      if (storedAuthToken) {
        this.$authToken.set(storedAuthToken);
      }
    }
    if (TOKEN_STATUS_PENDING === this.$refreshToken()) {
      const storedRefreshToken =
        this.localStorageService.getItem(REFRESH_TOKEN_KEY);
      if (storedRefreshToken) {
        this.$refreshToken.set(storedRefreshToken);
      }
    }
    effect(() => {
      const payload = this.$authTokenPayload();
      if (payload) {
        this.localStorageService.setItem(
          ACTIVE_SESSION_KEY,
          payload.sessionId ?? payload.accountId,
        );
      }
    });
    effect(() => {
      if (!this.$shouldUseLocalStorage()) {
        this.localStorageService.removeItem(AUTH_TOKEN_KEY);
        this.localStorageService.removeItem(REFRESH_TOKEN_KEY);
      }
    });
  }

  init() {
    this.addCrossTabListener();
    if (TOKEN_STATUS_PENDING === this.$authToken() && this.maybeLoggedIn()) {
      this.refreshToken()
        .pipe(
          catchError(async () => {
            await this.clearAuthSessionInfo();
            window.location.reload();
            return EMPTY;
          }),
        )
        .subscribe();
    }
  }

  protected addCrossTabListener() {
    this.localStorageService
      .getItem$(ACTIVE_SESSION_KEY, { excludeSelf: true, includeCurrent: true })
      .pipe(
        distinctUntilChanged(),
        pairwise(),
        tap(([oldValue, newValue]) => {
          const isLoginEvent = newValue && !oldValue;
          const isLogoutEvent = oldValue && !newValue;
          if ((isLogoutEvent && this.$isLoggedIn()) || isLoginEvent) {
            window.location.reload();
          }
          return EMPTY;
        }),
      )
      .subscribe();
    this.localStorageService
      .getItem$(AUTH_TOKEN_KEY, { excludeSelf: true, includeCurrent: true })
      .pipe(
        distinctUntilChanged(),
        tap((value) => {
          if (this.$shouldUseLocalStorage() && value) {
            this.$authToken.set(value);
          }
        }),
      )
      .subscribe();
    this.localStorageService
      .getItem$(REFRESH_TOKEN_KEY, { excludeSelf: true, includeCurrent: true })
      .pipe(
        distinctUntilChanged(),
        tap((value) => {
          if (this.$shouldUseLocalStorage() && value) {
            this.$refreshToken.set(value);
          }
        }),
      )
      .subscribe();
  }

  getAuthToken() {
    return this.$authToken();
  }

  $authTokenPayload = computed(() => {
    const authToken = this.$authToken();
    return authToken ? (decodeJwt(authToken) as AuthToken) : undefined;
  });

  protected maybeLoggedIn(): boolean {
    return (
      !!this.localStorageService.getItem(REFRESH_TOKEN_KEY) ||
      !!this.localStorageService.getItem(ACTIVE_SESSION_KEY)
    );
  }

  $isLoggedIn = computed(() => {
    const authToken = this.$authToken();
    return TOKEN_STATUS_PENDING === authToken
      ? this.maybeLoggedIn()
      : !!authToken;
  });

  /** Does not push a value until login status is known */
  isLoggedIn$(): Observable<boolean> {
    return concat(of(this.$authToken()), this.authToken$).pipe(
      filter((token) => TOKEN_STATUS_PENDING !== token),
      map((token) => Boolean(token)),
    );
  }

  private inflightTokenRequest?: Observable<AuthTokenResponse>;
  refreshToken(): Observable<AuthTokenResponse> {
    if (this.inflightTokenRequest) {
      return this.inflightTokenRequest;
    }
    const inflightSubject = new Subject<AuthTokenResponse>();
    this.inflightTokenRequest = inflightSubject.asObservable();
    const refreshToken = this.$refreshToken();
    return this.http
      .post<AuthTokenResponse>(REFRESH_TOKEN_URL, {
        ...(refreshToken && {
          refresh_token: refreshToken,
        }),
      })
      .pipe(
        tap((response) => {
          this.$authToken.set(response.token);
          if (response.refresh_token) {
            this.$refreshToken.set(response.refresh_token);
          }
          if (this.$shouldUseLocalStorage()) {
            this.localStorageService.setItem(AUTH_TOKEN_KEY, response.token);
            if (response.refresh_token) {
              this.localStorageService.setItem(
                REFRESH_TOKEN_KEY,
                response.refresh_token,
              );
            }
          }
          this.inflightTokenRequest = undefined;
          inflightSubject.next(response);
        }),
        catchError((error) => {
          this.$authToken.set(NO_TOKEN);
          return throwError(() => error);
        }),
        finalize(() => {
          inflightSubject.complete();
        }),
      );
  }

  login(credentials: LogInTokenCredentials) {
    return this.http.post(LOGIN_API_URL, credentials).pipe(
      tap((response: LoginResponse) => {
        this.$authToken.set(response.token);
        this.$refreshToken.set(response.refresh_token);
        if (this.$shouldUseLocalStorage()) {
          this.localStorageService.setItem(AUTH_TOKEN_KEY, response.token);
          this.localStorageService.setItem(
            REFRESH_TOKEN_KEY,
            response.refresh_token,
          );
        }
        this.analyticsService.sendAnalyticsIdentify(response.profile);
        if (response.isNewAccount) {
          this.analyticsService.setAnalyticsAlias(response.profile);
        }
      }),
    );
  }

  signUp(data: SignUpData, params?: SignUpParams) {
    return this.authBackend.signUp(data, params).pipe(
      tap((response: LoginResponse) => {
        this.$authToken.set(response.token);
        this.$refreshToken.set(response.refresh_token);
        if (this.$shouldUseLocalStorage()) {
          this.localStorageService.setItem(AUTH_TOKEN_KEY, response.token);
          this.localStorageService.setItem(
            REFRESH_TOKEN_KEY,
            response.refresh_token,
          );
        }
        this.analyticsService.sendAnalyticsIdentify(response.profile);
        if (response.isNewAccount) {
          this.analyticsService.setAnalyticsAlias(response.profile);
        }
      }),
    );
  }

  protected deleteSession() {
    return this.http.delete(SESSION_API_URL);
  }

  logout(callback: () => void = DEFAULT_LOGOUT_CALLBACK) {
    this.deleteSession()
      .pipe(
        switchMap(() => this.logger.logInfo({ message: 'Logout' })),
        finalize(async () => {
          await this.clearAuthSessionInfo();
          callback();
        }),
      )
      .subscribe();
  }

  protected async clearAuthSessionInfo() {
    this.$authToken.set(false);
    this.localStorageService.removeItem(AUTH_TOKEN_KEY);
    this.localStorageService.removeItem(REFRESH_TOKEN_KEY);
    this.localStorageService.removeItem(ACTIVE_SESSION_KEY);
    this.postHogService.reset();
    await this.analyticsService.clearAnalytics();
  }
}
