import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { shareReplay, Subject } from 'rxjs';
import {
  delay,
  filter,
  finalize,
  take,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { AnalyticsService } from '@libs/src/analytics/analytics.service';
import { PostHogService } from '@libs/src/posthog/post-hog.service';
import { ZendeskService } from '@libs/src/zendesk/zendesk.service';
import { AuthService } from '@main-client/src/app/auth/auth.service';
import { RouteListenerService } from '@main-client/src/app/core/route-listener.service';
import { UpdatesService } from '@main-client/src/app/core/updates.service';
import { TenantService } from '@main-client/src/app/tenant/tenant.service';
import { UserIdleTimeoutService } from '@main-client/src/app/user-idle-timeout/user-idle-timeout.service';
import { UserService } from '@main-client/src/app/user/user.service';

import { removeNonAngularErrorHandlers } from '@libs/src/logger/error-logger.helper';
import { Account } from '@libs/src/models/account.model';
import { Tenant } from '@libs/src/tenant/tenant.interface';
import { IAppState } from '@main-client/src/app/app.state';
import { isAnyAdmin } from '@main-client/src/app/user/user.helpers';

import defer from 'lodash-es/defer';
import isEmpty from 'lodash-es/isEmpty';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
})
export class RootComponent implements OnInit, OnDestroy {
  header = this.store.select('header');
  tenant = this.store.select('tenant');
  user = this.store.select('user');
  componentDestroy = new Subject<void>();
  isEmptyState = false;
  routeTitle: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly analyticsService: AnalyticsService,
    private readonly routeListenerService: RouteListenerService,
    private readonly router: Router,
    private readonly store: Store<IAppState>,
    private readonly tenantService: TenantService,
    private readonly updatesService: UpdatesService,
    private readonly postHogService: PostHogService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly userIdleTimeoutService: UserIdleTimeoutService,
    private readonly zendeskService: ZendeskService,
  ) {}

  ngOnInit(): void {
    removeNonAngularErrorHandlers();
    this.routeListenerService.init();
    this.analyticsService.initialize();
    this.tenantService.populate();
    this.authService.init();
    this.startAuthWatcher()
      .pipe(
        finalize(() => {
          this.userService.populate();

          defer(() => {
            this.updatesService.init();
          });
        }),
      )
      .subscribe();
    const user$ = this.user.pipe(
      filter((user: Account) => !isEmpty(user)),
      take(1),
      shareReplay(1),
    );
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        window.scroll(0, 0);
        this.isEmptyState = this.routeListenerService.findDataValue(
          this.activatedRoute.snapshot,
          'isEmptyState',
        ) as boolean;
        this.routeTitle = this.getRouteTitle(
          this.router.routerState.root.firstChild,
        );
      });
    user$
      .pipe(
        tap((user) => {
          this.initSessionIdleTimeout(user);
          this.postHogService.init(user);
          this.analyticsService.setAnalyticsAlias(user);
          this.zendeskService.updateUserProperties(user);
        }),
        delay(2000),
        tap(() => this.zendeskService.shutdown()),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.componentDestroy.next();
    this.componentDestroy.complete();
  }

  initSessionIdleTimeout(user: Account) {
    return this.tenant
      .pipe(
        filter((tenant: Tenant) => !isEmpty(tenant)),
        tap((tenant: Tenant) => {
          let idleTimeout: number | undefined;
          if (
            tenant.enabled_features.admin_lockout &&
            tenant.admin_lockout_time_minutes &&
            isAnyAdmin(user)
          ) {
            idleTimeout = tenant.admin_lockout_time_minutes;
          }
          if (
            tenant.enabled_features.session_idle_timeout &&
            tenant.session_idle_timeout_minutes &&
            (!idleTimeout || idleTimeout > tenant.session_idle_timeout_minutes)
          ) {
            idleTimeout = tenant.session_idle_timeout_minutes;
          }
          if (idleTimeout) {
            this.userIdleTimeoutService.setConfig({
              timeout: idleTimeout * 60_000,
            });
            return this.userIdleTimeoutService.start();
          }
          this.userIdleTimeoutService.stop();
        }),
        takeUntil(this.componentDestroy),
      )
      .subscribe();
  }

  getRouteTitle(currentRoute: ActivatedRoute): string {
    let title: string;

    while (currentRoute) {
      if (currentRoute.snapshot.routeConfig?.title) {
        title = currentRoute.snapshot.routeConfig.title as string;
      }

      currentRoute = currentRoute.firstChild;
    }

    return title;
  }

  startAuthWatcher() {
    return this.authService
      .isLoggedIn$()
      .pipe(takeWhile((isLoggedIn) => !isLoggedIn));
  }
}
