import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EmailVerificationDialogData } from '@main-client/src/app/email-verification-dialog/email-verification-dialog-data';

@Component({
  selector: 'app-email-verification-dialog',
  templateUrl: './email-verification-dialog.component.html',
})
export class EmailVerificationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmailVerificationDialogData,
  ) {}
}
