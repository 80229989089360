export const environment = {
  env: 'prod',
  firebase: {
    cloud_messaging_public_key:
      'BDX2xbfvWsx_g6cvrCFzS6CtQ2zoZGDOpZNcjda2msUyp4XUugi2uVS94tYM-xtCKR9U7S59F7WqsavO3xtV8bU',
  },
  googleMaps: {
    apiKey: 'AIzaSyC6_3eCSiYjKyuBBC-yjnQgDpeLYubnmIA',
  },
  postHog: {
    apiKey: 'phc_j3tsvgSNLWkI1t4KD9UEwAiEfDFcrTf3OBZEA4CnXan',
  },
  production: true,
  recaptcha: { siteKey: '6LccWgkaAAAAAKqVt5xdiuFlTu2d5ulHwerH_nwJ' },
  segment: {
    apiKey: '06rm7d9ixn',
  },
  workos: { envId: '01F6JRYSJRGPVWX9G18NJS8SE4' },
  workplace: {
    app_id: 1813135922313976,
  },
};
