import { Account } from '@libs/src/models/account.model';
import {
  Match,
  MatchAccount,
  MatchState,
} from '@libs/src/models/match.interfaces';

import find from 'lodash-es/find';
import findIndex from 'lodash-es/findIndex';
import get from 'lodash-es/get';
import head from 'lodash-es/head';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';

export function getUserMatchStatus(
  match: Match | MatchAccount[],
  userId: string,
): string {
  const matchAccounts = Array.isArray(match) ? match : match?.accounts;
  return get(
    find(matchAccounts, (account: MatchAccount) =>
      isEqual(account?.document?._id, userId),
    ),
    'status',
  );
}

export function getOtherUserMatchStatus(match: Match, userId: string): string {
  return get(
    find(
      match?.accounts,
      (account: MatchAccount) => !isEqual(account?.document?._id, userId),
    ),
    'status',
  );
}

export function updateMatch(match: Match, userId: string, status: string) {
  const matchAccounts = get(match, 'accounts') || [];
  if (isEmpty(matchAccounts)) {
    return match;
  }
  return {
    ...match,
    accounts: matchAccounts.map((matchAccount: MatchAccount) => ({
      ...matchAccount,
      status: isEqual(matchAccount?.document?._id, userId)
        ? status
        : matchAccount.status,
    })),
  };
}

export function findMatchStateByGroupIdIndex(
  matchesState: MatchState[],
  groupId: string,
) {
  return findIndex(
    matchesState,
    (matchState: MatchState) =>
      isEqual(matchState?.document?.group?._id, groupId) ||
      isEqual(matchState?.document?.group, groupId),
  );
}

export function findMatchStateByGroupId(
  matchesState: MatchState[],
  groupId: string,
) {
  return find(
    matchesState,
    (matchState: MatchState) =>
      isEqual(matchState?.document?.group?._id, groupId) ||
      isEqual(matchState?.document?.group, groupId),
  );
}

export function findMatchStateByMatchIdIndex(
  matchesState: MatchState[],
  matchId: string,
) {
  return findIndex(matchesState, (matchState: MatchState) =>
    isEqual(matchState?.document?._id, matchId),
  );
}

export function findMatchStateByMatchId(
  matchesState: MatchState[],
  matchId: string,
) {
  return find(matchesState, (matchState: MatchState) =>
    isEqual(matchState?.document?._id, matchId),
  );
}

export function getMatchesStatesForGroup(
  matchesState: MatchState[],
  groupId: string,
) {
  return matchesState.filter(
    (matchState: MatchState) =>
      isEqual(matchState?.document?.group?._id, groupId) ||
      isEqual(matchState?.document?.group, groupId),
  );
}

export function getMostRecentMatchState(matchesState: MatchState[]) {
  if (1 >= matchesState.length) {
    return head(matchesState);
  }
  const sortedMatchStates = matchesState.sort(
    (match1: MatchState, match2: MatchState) => {
      return (
        new Date(match2?.document?.created_at).getTime() -
        new Date(match1?.document?.created_at).getTime()
      );
    },
  );
  return head(sortedMatchStates);
}

export function findUserMatchAccountFromMatch(
  match: Match,
  user: Account,
): MatchAccount {
  return find(
    match?.accounts || [],
    ({ document }) => user._id === document?._id,
  );
}

export function findOtherAccountFromMatch(
  match: Match,
  user: Account,
): Account {
  return find(
    match?.accounts?.map(
      (matchAccount: MatchAccount) => matchAccount?.document,
    ),
    (account: Account) => user._id !== account?._id,
  );
}

export function findMatchById(matchId: string, matches: Match[] = []) {
  return find(matches, (match: Match) => matchId === match?._id);
}
