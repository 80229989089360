import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { UserIdleTimeoutService } from '@main-client/src/app/user-idle-timeout/user-idle-timeout.service';
import { UserService } from '@main-client/src/app/user/user.service';

import { UserIdleTimeoutDialogComponent } from '@main-client/src/app/user-idle-timeout/user-idle-timeout-dialog/user-idle-timeout-dialog.component';

@NgModule({
  declarations: [UserIdleTimeoutDialogComponent],
  exports: [UserIdleTimeoutDialogComponent],
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, MatDialogModule],
  providers: [UserIdleTimeoutService, UserService],
})
export class UserIdleTimeoutModule {}
