import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { AvatarModule } from '@libs/src/avatar/avatar.module';
import { ZendeskLinkModule } from '@libs/src/zendesk-link/zendesk-link.module';
import { LocaleManagerModule } from '@main-client/src/app/locale-manager/locale-manager.module';
import { MostReadableColorModule } from '@main-client/src/app/pipes/most-readable-color/most-readable-color.module';

import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule.forChild([]),
    AvatarModule,
    LocaleManagerModule,
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MostReadableColorModule,
    MatTooltipModule,
    ZendeskLinkModule,
  ],
})
export class HeaderModule {}
