import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_VERSION } from '@ttc/ttc-constants/app.metadata';
import browserUpdate from 'browser-update/update.npm';

import { AppModule } from './app/app.module';

import { environment } from '@libs/src/environments/environment';

if (environment.production) {
  enableProdMode();
}

browserUpdate({
  required: {
    e: -2,
    i: 11,
    f: -3,
    o: -3,
    s: 10.1,
    c: 64,
    samsung: 7.0,
    v: 1.2,
  },
});

document.documentElement.setAttribute('data-ttc-version', APP_VERSION);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
