import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { concatMap, filter, map, take, tap } from 'rxjs/operators';

import { LocaleSettingsService } from '@main-client/src/app/core/locale-settings.service';

import type { Account } from '@libs/src/models/account.model';
import type { Group } from '@libs/src/models/group.model';
import type { Tenant } from '@libs/src/tenant/tenant.interface';
import { SetUser } from '@libs/src/user/user.actions';
import type { IAppState } from '@main-client/src/app/app.state';

import isEmpty from 'lodash-es/isEmpty';

const USER_API_URL = '/api/account';

interface AdminStates {
  isPlatformAdmin: boolean;
  pathwayManagerInGroupIds: Array<Group['_id']>;
  programManagerInGroupIds: Array<Group['_id']>;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  tenantSource = this.store.select('tenant').pipe(
    filter((tenant: Tenant) => !isEmpty(tenant)),
    take(1),
  );
  userAdminStates = signal<AdminStates>({
    isPlatformAdmin: false,
    pathwayManagerInGroupIds: [],
    programManagerInGroupIds: [],
  });

  constructor(
    @Inject(LOCALE_ID) public appLocale: string,
    private readonly http: HttpClient,
    private readonly localeSettingsService: LocaleSettingsService,
    private readonly store: Store<IAppState>,
  ) {}

  populate() {
    this.http
      .get<{
        adminStates?: AdminStates;
        profile: Account;
      }>(USER_API_URL)
      .pipe(
        tap(({ adminStates = {} }) => {
          const {
            isPlatformAdmin = false,
            pathwayManagerInGroupIds = [],
            programManagerInGroupIds = [],
          } = adminStates;
          this.userAdminStates.set({
            isPlatformAdmin,
            pathwayManagerInGroupIds,
            programManagerInGroupIds,
          });
        }),
        concatMap(({ profile }: { profile: Account }) =>
          this.tenantSource.pipe(
            take(1),
            map((tenant) => {
              this.localeSettingsService.ensureBrowserLocale(
                tenant,
                profile?.locale_config?.locale_selected,
              );
              return profile;
            }),
          ),
        ),
        tap((profile: Account) => {
          this.store.dispatch(SetUser(profile));
        }),
      )
      .subscribe();
  }
}
