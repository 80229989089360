import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const LOCATION_AUTOCOMPLETE_API_URL = '/api/content/city-autocomplete';
const ADDRESS_AUTOCOMPLETE_API_URL = '/api/content/address-autocomplete';

interface AddressCompleteResponse {
  searchResults: any[];
}

interface ILocationAutoCompleteResponse {
  data: string[];
  placeId: string[];
}

@Injectable()
export class LocationService {
  constructor(private http: HttpClient) {}

  getAutocomplete(keyword: string): Observable<any> {
    if (!keyword) {
      return of([]);
    }

    const params = new HttpParams().set('input', keyword);
    return this.http
      .get(LOCATION_AUTOCOMPLETE_API_URL, { params })
      .pipe(map((response: ILocationAutoCompleteResponse) => response.data));
  }

  getAddressAutocomplete(keyword: string): Observable<any> {
    if (!keyword) {
      return of([]);
    }

    const params = new HttpParams().set('searchTerm', keyword);
    return this.http
      .get(ADDRESS_AUTOCOMPLETE_API_URL, { params })
      .pipe(map((response: AddressCompleteResponse) => response.searchResults));
  }
}
