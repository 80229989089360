import { Action } from '@ngrx/store';

import { Group, GroupRole } from '@libs/src/models/group.model';

export const GROUPS_GET_TYPE = '[groups] get group';
export const GROUPS_GET_SUCCESS_TYPE = '[groups] get group success';
export const GROUPS_UPDATE_TYPE = '[groups] update group';
export const GROUPS_UPDATE_SUCCESS_TYPE = '[groups] update group success';
export const GROUPS_UPDATE_ERROR_TYPE = '[groups] update group error';
export const GROUPS_ADD_ROLE_TYPE = '[groups] update add role';
export const GROUPS_DELETE_CATEGORIES_TYPE = '[groups] delete categories';
export const GROUPS_DELETE_ROLE_TYPE = '[groups] update delete role';
export const GROUPS_UPDATE_ROLE_TYPE = '[groups] update group role';
export const GROUPS_UPDATE_ROLE_SUCCESS_TYPE =
  '[groups] update group role success';
export const GROUPS_UPDATE_CONFIG_TYPE = '[groups] update group config';

export class GroupsAction implements Action {
  constructor(
    public type = '',
    public payload: any = {},
  ) {}
}

export function GetGroup(payload: any): GroupsAction {
  return new GroupsAction(GROUPS_GET_TYPE, payload);
}

export function GetGroupSuccess(payload: any): GroupsAction {
  return new GroupsAction(GROUPS_GET_SUCCESS_TYPE, payload);
}

export function UpdateGroup(payload: {
  group: Group;
  isSilent?: boolean;
  originalGroup?: Group;
}): GroupsAction {
  return new GroupsAction(GROUPS_UPDATE_TYPE, payload);
}

export function UpdateGroupSuccess(payload: { group: Group }): GroupsAction {
  return new GroupsAction(GROUPS_UPDATE_SUCCESS_TYPE, payload);
}

export function UpdateGroupError(payload: { group: Group }): GroupsAction {
  return new GroupsAction(GROUPS_UPDATE_ERROR_TYPE, payload);
}

export function UpdateGroupRoleSuccess(payload: {
  group: Group;
  roles: GroupRole[];
}): GroupsAction {
  return new GroupsAction(GROUPS_UPDATE_ROLE_SUCCESS_TYPE, payload);
}

export function UpdateGroupConfig(payload: {
  group: Group;
  update: { joinRequestsCount: number };
}) {
  return new GroupsAction(GROUPS_UPDATE_CONFIG_TYPE, payload);
}
