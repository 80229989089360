import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { DestroySubscriptionsService } from '@libs/src/destroy-subscription/destroy-subscription.service';
import { ZendeskService } from '@libs/src/zendesk/zendesk.service';
import { AuthService } from '@main-client/src/app/auth/auth.service';

import {
  ResetUnseenConversationsCount,
  ResetUnseenNotificationsCount,
} from './header.actions';
import {
  Account,
  AccountGroupMembership,
} from '@libs/src/models/account.model';
import { Tenant } from '@libs/src/tenant/tenant.interface';
import { getMembership } from '@main-client/src/app/account/account.helpers';
import { GetHubs } from '@main-client/src/app/hubs/hubs.actions';
import { NavigationRouteConfig } from '@main-client/src/app/main-navigation/main-navigation.interfaces';

import { IAppState } from '../app.state';

import { LocaleManagerModalService } from '../locale-manager/locale-manager-modal.service';

import isEmpty from 'lodash-es/isEmpty';

const resetCountTypes = {
  conversations: ResetUnseenConversationsCount(),
  notifications: ResetUnseenNotificationsCount(),
};

@Component({
  providers: [DestroySubscriptionsService],
  selector: 'app-header-nav',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input()
  title: string;
  @Input()
  routes: NavigationRouteConfig[];
  @Input()
  user: Account;
  @Input()
  hasCustomDrawerAction: boolean;
  @Output()
  customDrawerAction = new EventEmitter();
  isLoading: boolean;
  currentGroup = this.store.select('currentGroup');
  header = this.store.select('header');
  tenant: Tenant;
  showDrawer = false;
  currentMembership: AccountGroupMembership;

  constructor(
    private readonly destroy$: DestroySubscriptionsService,
    private readonly localeManagerModalService: LocaleManagerModalService,
    private readonly store: Store<IAppState>,
    private readonly zendesk: ZendeskService,
    public readonly authService: AuthService,
  ) {}

  ngOnInit() {
    if (this.authService.$isLoggedIn()) {
      this.store.dispatch(GetHubs({}));
    }
    this.store
      .select('tenant')
      .pipe(
        filter((tenant: Tenant) => !isEmpty(tenant)),
        tap((result) => {
          this.tenant = result;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnChanges() {
    this.currentGroup.pipe(take(1)).subscribe((item) => {
      if (isEmpty(this.user) || isEmpty(this.user.groups)) {
        this.currentMembership = null;
        return;
      }
      this.currentMembership = item
        ? getMembership(this.user.groups, item)
        : this.user.groups[0];
    });
  }

  logOut() {
    this.authService.logout();
  }

  resetUnseenCount(type: string) {
    this.store.dispatch((resetCountTypes as any)[type]);
  }

  showSideNav(drawer: any) {
    if (this.hasCustomDrawerAction) {
      this.customDrawerAction.emit();
      return;
    }

    this.showDrawer = true;
    drawer.open();
  }

  drawerOpenedChange($event: boolean) {
    this.showDrawer = $event;
  }

  helpCenterClicked() {
    this.zendesk.navigate('https://support.tenthousandcoffees.com');
  }

  showZendesk() {
    this.zendesk.showZendesk();
  }

  openLanguageManager() {
    this.localeManagerModalService.openLanguageManager(this.user);
  }
}
