<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
  @switch (data.type) {
    @case ('success') {
      <mat-icon class="color-teal u-display-block" fxFlex="24px">
        check
      </mat-icon>
    }
    @case ('error') {
      <mat-icon class="color-orange-900 u-display-block" fxFlex="24px">
        close
      </mat-icon>
    }
  }
  <span i18n="@@customToast.message">{data.message, select,
    other {{{ data.message }}}
  }</span>
  @if (data.action) {
    <span fxFlex></span>
    <button
      [ngStyle]="{ backgroundColor: 'white' }"
      (click)="snackBarRef.dismissWithAction()"
      fxFlex="none"
      mat-button
      i18n="@@customToast.actionButton"
    >
      {{ data.action }}
    </button>
  }
</div>
