import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { Tenant } from '@libs/src/tenant/tenant.interface';
import { IAppState } from '@main-client/src/app/app.state';

@Injectable({ providedIn: 'root' })
class OnboardingV2GuardService {
  constructor(
    private readonly router: Router,
    private readonly store: Store<IAppState>,
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot) {
    return this.store.select('tenant').pipe(
      take(1),
      filter((tenant: Tenant) => !!tenant),
      map((tenant) => {
        if (!tenant.enabled_features?.new_onboarding) {
          this.router.navigate(['get-started'], {
            queryParams: snapshot.queryParams,
          });
          return false;
        }
        return true;
      }),
    );
  }
}

export const OnboardingV2Guard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
): Observable<boolean> => inject(OnboardingV2GuardService).canActivate(next);
