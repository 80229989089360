import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocaleBackend } from '@libs/src/locale/locale.backend';

import { Memoize } from '@libs/src/functions/memoize';
import {
  ContentLocalization,
  ContentLocalizationResponse,
} from '@libs/src/locale/locale.interface';

@Injectable()
export class ContentLocalizationService {
  constructor(private readonly localeBackend: LocaleBackend) {}

  @Memoize()
  getContentLocalization(
    contentLocalization: Partial<ContentLocalization>,
  ): Observable<ContentLocalization> {
    return this.localeBackend
      .translateContent(contentLocalization)
      .pipe(
        map(
          (result: ContentLocalizationResponse) => result.contentLocalization,
        ),
      );
  }

  updateContentLocalization(contentLocalization: Partial<ContentLocalization>) {
    return this.localeBackend
      .updateContentLocalization(contentLocalization)
      .pipe(
        map(
          (result: ContentLocalizationResponse) => result.contentLocalization,
        ),
      );
  }

  hasIncompleteUnitIdentifiers(
    contentLocalization: Partial<ContentLocalization>,
  ): boolean {
    return (
      !!contentLocalization.translation_unit !==
      !!contentLocalization.translation_unit_identifier
    );
  }
}
