import { OpportunitySubmission } from '@main-client/src/app/opportunity/opportunity.model';

import isEqual from 'lodash-es/isEqual';

export const SUBMISSION_FILTER_QUERIES = {
  event: {
    confirmed: (submission: OpportunitySubmission) => !submission.waitlist,
    waitlisted: (submission: OpportunitySubmission) => submission.waitlist,
  },
  exclusive_event: {
    confirmed: (submission: OpportunitySubmission) =>
      isEqual(submission.selected_status, 'selected'),
    waitlisted: (submission: OpportunitySubmission) =>
      isEqual(submission.selected_status, 'not-selected'),
  },
  active: (submission: OpportunitySubmission) =>
    !isEqual('inactive', submission.status) && submission.accountId,
  cancelled: (submission: OpportunitySubmission) =>
    isEqual('inactive', submission.status) || !submission.accountId,
  userSubmission: (submission: OpportunitySubmission, userId: string) =>
    isEqual(submission.accountId._id, userId),
};
