import { Injectable } from '@angular/core';

import { AccountGroupMembership } from '@libs/src/models/account.model';

import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';

const ADMIN_FIELD_NAME = 'isAdmin';

@Injectable()
export class GroupMembershipService {
  getMembership(groups: AccountGroupMembership[] = [], groupId: string) {
    return groups.find((group) => isEqual(group.document._id, groupId));
  }

  isGroupAdmin(groups: AccountGroupMembership[] = [], groupId: string) {
    return !!get(this.getMembership(groups, groupId), ADMIN_FIELD_NAME);
  }
}
