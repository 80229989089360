<div *nzModalTitle>
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="u-marginRight-8 md-18">language</mat-icon
    ><ng-container i18n="@@global.languageSettings"
      >Language settings</ng-container
    >
  </div>
</div>
<app-translate-page-toggle
  [isLoading]="isLoading"
  class="u-display-block"
></app-translate-page-toggle>
@if (!$isCurrentLocaleAllowed() && !isLoading) {
  <p class="color-grey-300" i18n="@@localeManager.restrictedLocalesPrompt">
    {{ currentUserLocale.translatedName }} is not supported by some of the Hubs
    you are a member of. The following languages are recommended for a better
    experience:
  </p>
  <ul class="u-marginBottom-8">
    @for (localeName of allowedLocaleNames$(); track $index) {
      <li
        [ngStyle]="{ 'list-style-position': 'inside' }"
        class="color-grey-300"
      >
        {{ localeName }}
      </li>
    }
  </ul>
}
<p
  class="color-grey-300 u-marginBottom-16"
  i18n="@@localeManager.selectLanguage"
>
  Choose the language used in the user interface.
</p>
<div fxLayout="row wrap" fxLayoutGap="8px grid">
  @for (locale of localesInSelector$() | keyvalue; track locale.key) {
    <div>
      <button
        [disabled]="isLoading"
        [ngStyle]="{
          'font-weight': 400,
          height: 'auto',
          'justify-content': 'flex-start',
          padding: '16px',
          width: '232px',
        }"
        [style.border]="
          locale.value.code === currentUserLocale.code ? '1px solid' : 'none'
        "
        (click)="switchLocale(locale.value)"
        class="u-borderRadius--4px u-textAlign-left"
        mat-button
      >
        <p class="text-subtitle-1">
          {{ locale.value.nativeDisplayName }}
          @if (locale.value.beta) {
            <span
              [ngStyle]="{ padding: '4px' }"
              class="u-marginLeft-8 bg-color-grey-100 color-grey text-caption u-borderRadius--4px"
              i18n="@@global.beta"
              >Beta</span
            >
          }
        </p>
        <p>{{ locale.value.locationName }}</p>
        <p class="color-grey-300 u-marginTop-8">
          {{ locale.value.translatedName }}
        </p>
      </button>
    </div>
  }
</div>
