import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Action } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';

import {
  GET_ALL_HUBS_TYPE,
  GET_HUBS_TYPE,
  GetHubsSuccess,
  REMOVE_HUBS_FILTER_TYPE,
  SET_HUBS_FILTERS_TYPE,
  SET_HUBS_KEYWORD_TYPE,
} from './hubs.actions';
import { AccountGroupMembership } from '@libs/src/models/account.model';
import { Group } from '@libs/src/models/group.model';
import { PENDING_MEMBERSHIP_STATUS } from '@main-client/src/app/membership-settings/membership-settings.enum';

import { HubsState, IAppState } from '../app.state';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import pick from 'lodash-es/pick';

const GROUPS_ADMIN_API_URL = '/api/admin/groups';
const GROUPS_API_V3_URL = '/api/v3/groups';
const VALID_PARAMS_FOR_ADMIN = [
  'group_type',
  'invisible',
  'join_type',
  'keyword',
  'limit',
  'skip',
  'status',
];
const VALID_PARAMS_FOR_NON_ADMIN = ['excludeIds', 'limit', 'skip'];

@Injectable()
export class HubsEffectsService {
  constructor(
    private actions: Actions,
    private http: HttpClient,
    private store: Store<IAppState>,
  ) {}

  getHubs: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(GET_HUBS_TYPE),
      mergeMap((action: any) => this.fetchHubs(action.payload)),
      map((response: { items: Group[]; totalCount: number }) =>
        GetHubsSuccess(response),
      ),
    ),
  );

  setFilters: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SET_HUBS_FILTERS_TYPE),
      mergeMap((action: any) => {
        return this.store.select('hubs').pipe(
          take(1),
          map((hubsState: HubsState) => ({
            ...action.payload,
            keyword: hubsState.keyword,
          })),
        );
      }),
      mergeMap((params: any) => this.getHubsForAdmin(params)),
      map((response: { items: Group[]; totalCount: number }) =>
        GetHubsSuccess(response),
      ),
    ),
  );

  setKeyword: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SET_HUBS_KEYWORD_TYPE),
      mergeMap((action: any) => {
        return this.store.select('hubs').pipe(
          take(1),
          mergeMap((hubsState: HubsState) => {
            const params = {
              ...hubsState.searchFilters,
              keyword: action.payload,
            };
            return this.fetchHubs(params).pipe(
              map((response: { items: Group[] }) => ({
                items: response.items,
                totalCount: hubsState.totalCount,
              })),
              map((update: any) => GetHubsSuccess(update)),
            );
          }),
        );
      }),
    ),
  );

  removeFilter: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(REMOVE_HUBS_FILTER_TYPE),
      mergeMap((action: any) => {
        return this.store.select('hubs').pipe(
          take(1),
          map((hubsState: HubsState) => ({
            ...hubsState.searchFilters,
            keyword: hubsState.keyword,
          })),
        );
      }),
      mergeMap((params: any) => this.getHubsForAdmin(params)),
      map((response: { items: Group[]; totalCount: number }) =>
        GetHubsSuccess(response),
      ),
    ),
  );

  getAllHubs: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(GET_ALL_HUBS_TYPE),
      mergeMap((action: any) => this.fetchHubs(action.payload)),
      mergeMap((response: { items: Group[]; totalCount: number }) => {
        const params = {
          limit: response.totalCount,
          skip: response.items.length,
        };
        return this.fetchHubs(params).pipe(
          map((fetchMoreResponse: { items: Group[]; totalCount: number }) => {
            const allHubs = {
              items: [...fetchMoreResponse.items, ...response.items],
              totalCount: fetchMoreResponse.totalCount,
            };
            return GetHubsSuccess(allHubs);
          }),
        );
      }),
    ),
  );

  getHubsForAdmin(getParams: any = {}) {
    const sanitizedParams = pick(getParams, VALID_PARAMS_FOR_ADMIN);
    return this.http
      .get(GROUPS_ADMIN_API_URL, {
        params: {
          limit: '10',
          ...sanitizedParams,
        },
      })
      .pipe(
        map((response: any) => ({
          items: response.groups,
          totalCount: response.count,
        })),
        catchError((response: any) => {
          const error = get(response, 'error.message', response.message);
          return throwError(error);
        }),
      );
  }

  getHubsForNonAdmin(getParams: any = {}) {
    const sanitizedParams = pick(getParams, VALID_PARAMS_FOR_NON_ADMIN);
    return this.http
      .get(GROUPS_API_V3_URL, {
        params: {
          limit: '10',
          name: getParams.keyword || '',
          ...sanitizedParams,
        },
      })
      .pipe(
        map((response: any) => ({
          items: response.results,
          totalCount: response.count,
        })),
        catchError((response: any) => {
          const error = get(response, 'error.message', response.message);
          return throwError(error);
        }),
      );
  }

  fetchHubs(params: any) {
    return this.store.select('user').pipe(
      filter((userState: any) => !isEmpty(userState)),
      take(1),
      mergeMap((userState: any) => {
        const rejectedMemberships =
          userState.pending_groups?.filter((membership: any) =>
            isEqual(PENDING_MEMBERSHIP_STATUS.rejected, membership.status),
          ) || [];
        const excludeIds = [
          ...rejectedMemberships.map((membership: AccountGroupMembership) =>
            get(membership, 'document._id', membership.document),
          ),
        ];
        return userState.isAdmin
          ? this.getHubsForAdmin(params)
          : this.getHubsForNonAdmin({ ...params, excludeIds });
      }),
    );
  }
}
