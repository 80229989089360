import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import { ButtonShakeTriggerDirective } from './button-shake-trigger.directive';

@NgModule({
  declarations: [ButtonShakeTriggerDirective],
  exports: [
    ButtonShakeTriggerDirective,
    MatButtonModule,
    MatIconModule,
    NzIconModule,
    NzSpinModule,
  ],
  imports: [MatButtonModule, MatIconModule, NzSpinModule],
})
export class TtcButtonSharedModule {}
