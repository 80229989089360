import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SetTenant } from './tenant.actions';
import { Tenant } from '@libs/src/tenant/tenant.interface';

import { IAppState } from '../app.state';

const TENANT_API_URL = '/api/admin/tenant';

@Injectable({ providedIn: 'root' })
export class TenantMutationService {
  constructor(
    private http: HttpClient,
    private store: Store<IAppState>,
  ) {}

  updateTenant(update: any): Observable<Partial<Tenant>> {
    return this.http
      .patch<Partial<Tenant>>(TENANT_API_URL, update)
      .pipe(tap((response) => this.store.dispatch(SetTenant(response))));
  }

  addTenantApprovedDomains(domain: string): Observable<any> {
    return this.http
      .put(`${TENANT_API_URL}/approved_domains/${domain}`, {})
      .pipe(
        tap((response: { approved_domains: string[] }) =>
          this.store.dispatch(SetTenant(response)),
        ),
      );
  }

  removeTenantApprovedDomains(domain: string): Observable<any> {
    return this.http
      .delete(`${TENANT_API_URL}/approved_domains/${domain}`)
      .pipe(
        tap((response: { approved_domains: string[] }) =>
          this.store.dispatch(SetTenant(response)),
        ),
      );
  }
}
