import { Component, Input, OnChanges } from '@angular/core';

import { AvatarSizes } from '@libs/src/avatar/avatar.types';

import head from 'lodash-es/head';
import includes from 'lodash-es/includes';

enum AVATAR_SIZES {
  regular = 32,
  small = 24,
  large = 48,
  xlarge = 96,
  xxlarge = 120,
}

const VALID_SIZES = [
  AVATAR_SIZES.regular,
  AVATAR_SIZES.small,
  AVATAR_SIZES.large,
  AVATAR_SIZES.xlarge,
  AVATAR_SIZES.xxlarge,
] as const;

@Component({
  selector: 'app-avatar',
  styleUrls: ['./avatar.component.scss'],
  templateUrl: './avatar.component.html',
})
export class AvatarComponent implements OnChanges {
  @Input() name: string;
  @Input() imageUrl: string;
  @Input() size: AvatarSizes;
  @Input() showCamera: boolean;
  AVATAR_SIZES = AVATAR_SIZES;

  ngOnChanges() {
    this.size = includes(VALID_SIZES, this.size)
      ? this.size
      : head(VALID_SIZES);
  }
}
