import { createSelector, select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DestroySubscriptionsService } from '@libs/src/destroy-subscription/destroy-subscription.service';

import { GroupsState } from '@libs/src/groups/groups-store/groups.interfaces';
import { Group } from '@libs/src/models/group.model';
import { IAppState } from '@main-client/src/app/app.state';

import find from 'lodash-es/find';
import isEmpty from 'lodash-es/isEmpty';

const selectCurrentGroup = (state: IAppState) => state.currentGroup;
const selectGroupStates = (state: IAppState) => state.groups;
const selectGroup = createSelector(
  selectCurrentGroup,
  selectGroupStates,
  (currentGroup: string, groupStates: GroupsState[]) => {
    const state = find(groupStates, { document: { _id: currentGroup } });
    return state && state.document;
  },
);
export function currentGroupSource(
  store: Store<IAppState>,
  componentDestroy: Subject<any> | DestroySubscriptionsService,
): Observable<Group> {
  return store.pipe(
    takeUntil(componentDestroy),
    select(selectGroup),
    filter((group) => !isEmpty(group)),
  );
}
