import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';

import { AuthService } from '@main-client/src/app/auth/auth.service';

import { IAppState } from '../app.state';
import { isAnyAdmin } from '../user/user.helpers';

import isEmpty from 'lodash-es/isEmpty';

@Injectable()
export class AnyAdminGuardService implements CanActivate {
  constructor(
    private readonly store: Store<IAppState>,
    private readonly authService: AuthService,
  ) {}

  canActivate() {
    if (!this.authService.$isLoggedIn()) {
      return false;
    }
    return this.store.select('user').pipe(
      filter((user: any) => !isEmpty(user)),
      take(1),
      map((user: any) => isAnyAdmin(user)),
    );
  }
}
