import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { CustomToastComponent } from './custom-toast.component';

import { TOAST_TYPES, ToastData } from '@libs/src/models/custom-toast.model';

const ACTION_TOAST_DURATION_IN_MS = 3000;
const TOAST_DURATION_IN_MS = 5000;

@Injectable({ providedIn: 'root' })
export class ToastService {
  private readonly toastTrigger = new Subject<string>();
  private readonly successToastTrigger = new Subject<string>();
  private readonly errorToastTrigger = new Subject<string>();

  constructor(private readonly toast: MatSnackBar) {
    this.toastTrigger
      .pipe(throttleTime(TOAST_DURATION_IN_MS))
      .subscribe((message: string) =>
        this.showToast({
          data: {
            message,
          },
        }),
      );
    this.successToastTrigger
      .pipe(throttleTime(TOAST_DURATION_IN_MS))
      .subscribe((message: string) =>
        this.showToast({
          data: {
            message,
            type: TOAST_TYPES.success,
          },
        }),
      );
    this.errorToastTrigger
      .pipe(throttleTime(TOAST_DURATION_IN_MS))
      .subscribe((message: string) =>
        this.showToast({
          data: {
            message,
            type: TOAST_TYPES.error,
          },
        }),
      );
  }

  show(message: string) {
    this.toastTrigger.next(message);
  }

  showSuccess(message: string) {
    this.successToastTrigger.next(message);
  }

  showError(message: string) {
    this.errorToastTrigger.next(message);
  }

  showAction(message: string, actionButton: string) {
    return this.showToast({
      data: {
        message,
        action: actionButton,
        type: TOAST_TYPES.success,
      },
      duration: ACTION_TOAST_DURATION_IN_MS,
    });
  }

  private showToast({
    message,
    duration = TOAST_DURATION_IN_MS,
    data,
  }: ToastData): MatSnackBarRef<CustomToastComponent> {
    const toastConfig = {
      data,
      duration,
      horizontalPosition: 'center' as MatSnackBarHorizontalPosition,
      verticalPosition: 'bottom' as MatSnackBarVerticalPosition,
    };
    if (message) {
      return this.toast.open(message, '', toastConfig);
    }
    return this.toast.openFromComponent(CustomToastComponent, toastConfig);
  }
}
