import { feedReducer } from './feed/feed.reducer';
import { sideNavReducer } from './groups/group.reducer';
import { headerReducer } from './header/header.reducer';
import { matchesReducer } from './matches/matches.reducer';
import { notificationsReducer } from './notifications/notifications.reducer';
import { tenantReducer } from './tenant/tenant.reducer';
import { currentGroupReducer } from '@main-client/src/app/current-group-store/current-group.reducer';

export const appReducers = {
  currentGroup: currentGroupReducer,
  feed: feedReducer,
  header: headerReducer,
  sideNav: sideNavReducer,
  matches: matchesReducer,
  notifications: notificationsReducer,
  tenant: tenantReducer,
};
