import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import get from 'lodash-es/get';

@Injectable()
export class AdminLegacyGuardService {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const tabName = get(route, 'queryParams.tabName');
    switch (tabName) {
      case 'hubs': {
        return this.router.createUrlTree(['/admin', 'hubs']);
      }
      case 'members': {
        return this.router.createUrlTree(['/admin', 'members']);
      }
      case 'admins': {
        return this.router.createUrlTree(['/admin', 'admins']);
      }
      case 'invitations': {
        return this.router.createUrlTree(['/home']);
      }
      case 'settings': {
        return this.router.createUrlTree(['/admin', 'settings']);
      }
      default: {
        return true;
      }
    }
  }
}
