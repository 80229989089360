import { Injectable, Injector } from '@angular/core';
import { POSTHOG_DOMAIN } from '@ttc/ttc-constants/posthog';
import posthog from 'posthog-js';
import type { PostHog } from 'posthog-js';

import { AuthService } from '@main-client/src/app/auth/auth.service';

import { environment } from '@libs/src/environments/environment';
import { environment as prodEnv } from '@libs/src/environments/environment.production';
import type { Account } from '@libs/src/models/account.model';
import { generateSubdomainUrl } from '@libs/src/utilities/url.utilities';
import { isTenThousandCoffeesAdmin } from '@main-client/src/app/user/user.helpers';

import pick from 'lodash-es/pick';

@Injectable({ providedIn: 'root' })
export class PostHogService {
  readonly postHogAPIHost = 'https://eu.i.posthog.com';
  readonly proxiedAPIHost = generateSubdomainUrl(POSTHOG_DOMAIN);
  readonly postHogUIHost = 'https://eu.posthog.com';
  postHogInstance?: PostHog;

  constructor(private readonly injector: Injector) {}

  protected getAuthTokenPayload() {
    const authService = this.injector.get(AuthService);
    return authService.$authTokenPayload();
  }

  isProd() {
    return prodEnv.env === environment.env;
  }

  init(user: Account) {
    const apiHost = environment.production
      ? this.proxiedAPIHost
      : this.postHogAPIHost;
    posthog.init(environment.postHog.apiKey, {
      api_host: apiHost,
      disable_session_recording: true,
      loaded: (instance) => {
        this.postHogInstance = instance;
        const authTokenPayload = this.getAuthTokenPayload();
        const userId = authTokenPayload?.superAdmin ?? user._id;
        const isSuperAdmin = !!authTokenPayload?.superAdmin;
        this.postHogInstance.identify(userId);
        this.postHogInstance.register_for_session({
          isSuperAdmin,
          assumedUser: isSuperAdmin
            ? pick(user, ['_id', 'full_name', 'email'])
            : undefined,
        });
        if (
          user.opted_for_screen_recording ||
          (this.isProd() && isTenThousandCoffeesAdmin(user))
        ) {
          this.startSessionRecording();
        }
      },
      ui_host: this.postHogUIHost,
    });
  }

  startSessionRecording() {
    this.postHogInstance?.startSessionRecording();
  }

  isFeatureEnabled(featureName: string) {
    return this.postHogInstance?.isFeatureEnabled(featureName);
  }

  reset() {
    this.postHogInstance?.reset();
  }
}
