import {
  USER_CLEAR_TYPE,
  USER_INIT_SAVE_TYPE,
  USER_SET_SINGLE_GROUP_TYPE,
  USER_SET_SINGLE_MEMBERSHIP_TYPE,
  USER_SET_TYPE,
  USER_UPDATE_GROUP_TYPE,
  USER_UPDATE_PENDING_GROUP_TYPE,
  UserAction,
} from './user.actions';

import { Account, AccountGroupMembership } from '../models/account.model';

import findIndex from 'lodash-es/findIndex';
import has from 'lodash-es/has';
import isEmpty from 'lodash-es/isEmpty';

const INITIAL_STATE: Account = {};

export function userReducer(
  state: Account = INITIAL_STATE,
  action: UserAction,
) {
  switch (action.type) {
    case USER_CLEAR_TYPE: {
      return INITIAL_STATE;
    }

    case USER_INIT_SAVE_TYPE:
    case USER_SET_TYPE: {
      const updatedState = {
        ...state,
        ...action.payload,
      };
      const groups = action.payload.groups;
      if (has(action.payload, 'groups')) {
        updatedState.groups = sanitizeEmptyGroups(groups || []);
      }

      return updatedState;
    }

    case USER_SET_SINGLE_GROUP_TYPE: {
      const groups = [...state.groups];
      const groupIndex = findIndex(groups, {
        document: { _id: action.payload.group._id },
      });
      if (-1 === groupIndex) {
        return { ...state };
      }
      groups[groupIndex] = {
        ...groups[groupIndex],
        document: {
          ...groups[groupIndex].document,
          ...action.payload.group,
        },
      };
      return {
        ...state,
        groups,
      };
    }

    case USER_SET_SINGLE_MEMBERSHIP_TYPE: {
      const memberships = [...state.groups];
      const membershipIndex = findIndex(memberships, {
        _id: action.payload.membership._id,
      });
      memberships[membershipIndex] = action.payload.membership;
      return {
        ...state,
        groups: memberships,
      };
    }

    case USER_UPDATE_GROUP_TYPE: {
      return {
        ...state,
        groups: action.payload,
      };
    }

    case USER_UPDATE_PENDING_GROUP_TYPE: {
      return {
        ...state,
        pending_groups: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

function sanitizeEmptyGroups(userGroups: AccountGroupMembership[]) {
  return userGroups.filter((membership) => !isEmpty(membership.document));
}
