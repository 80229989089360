import {
  Account,
  AccountConversationGoal,
  AccountGroupMembership,
  MatchExclusion,
} from '@libs/src/models/account.model';
import { Group, GroupCustomSegmentNode } from '@libs/src/models/group.model';
import { MatchConfig } from '@libs/src/models/match.interfaces';
import { getUserSelectedNodesForCustomSegment } from '@libs/src/segment-selections/segment-selections.helper';
import { generateNodesPerEdge } from '@main-client/src/app/group-custom-segments-settings/group-custom-segments.helper';
import { CustomSegmentsSelections } from '@main-client/src/app/group-intro-selections-form/custom-segment-selections';
import { MatchSelections } from '@main-client/src/app/group-intro-selections-form/match-selections';
import { RoleSelections } from '@main-client/src/app/group-intro-selections-form/role-selections';

import difference from 'lodash-es/difference';
import find from 'lodash-es/find';
import get from 'lodash-es/get';
import includes from 'lodash-es/includes';
import isEmpty from 'lodash-es/isEmpty';
import isString from 'lodash-es/isString';
import keys from 'lodash-es/keys';
import mapValues from 'lodash-es/mapValues';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';
import startsWith from 'lodash-es/startsWith';
import uniq from 'lodash-es/uniq';

export function getMembership(
  memberships: AccountGroupMembership[] = [],
  groupId: Group['_id'],
): AccountGroupMembership {
  return (find(memberships, { document: { _id: groupId } }) ||
    find(memberships, { document: groupId } as any)) as AccountGroupMembership;
}

export function getUserConversationGoalForGroup(
  user: Account,
  groupId: Group['_id'],
): AccountConversationGoal | undefined {
  const accountMembership = getMembership(user.groups, groupId);
  return accountMembership && get(accountMembership, 'conversation_goal');
}

export function hasFinishedOnboarding(user: Account) {
  const featuresSeen = get(user, 'features_seen', []);
  return featuresSeen.some((featureSeen) =>
    startsWith(featureSeen, 'onboarding-success'),
  );
}

export function userHasSeenFeature(
  user: Account,
  featureIdentifier: string,
): boolean {
  return isEmpty(user) || includes(user.features_seen, featureIdentifier);
}

export function getSelectedRolesForIntroduction(
  membership: AccountGroupMembership,
  group: Group,
  matchConfig: MatchConfig,
): string[] {
  if (!membership || !group) {
    return [];
  }
  const matchSegmentForRole = find(
    matchConfig.match_segments,
    (segment) => membership.role === segment.value,
  );
  const rolesToMatchWith = get(matchSegmentForRole, 'match_with', []);
  const groupRoles =
    'preferred' === matchConfig.match_segment_level
      ? group.roles?.map((role) => role.name)
      : rolesToMatchWith;
  const accountMatchExclusions = get(membership, 'match_exclusions', []);
  const roleExclusions = getMatchExclusionsByType(
    accountMatchExclusions,
    'match_segment',
  );
  const excludedRoles = roleExclusions
    .map((exclusion) => exclusion.exclude_string)
    .filter(isString);
  return difference(groupRoles ?? [], excludedRoles);
}

export function getSelectedCustomSegmentsForIntroduction(
  membership: AccountGroupMembership,
  group: Group,
  matchConfig: MatchConfig,
): { nodes: string[]; selectedNodes: string[]; title?: string }[] {
  if (!membership || !group) {
    return [];
  }
  const accountMatchExclusions = get(membership, 'match_exclusions', []);
  const customSegmentExclusions = getMatchExclusionsByType(
    accountMatchExclusions,
    'custom_segment',
  );
  return (group?.custom_segments || [])
    .filter(
      (customSegment) =>
        customSegment.is_selectable_for_user_introduction_goals,
    )
    .reduce((result, customSegment) => {
      const customSegmentConfig = find(
        matchConfig.custom_segment_configs,
        (config) => customSegment._id === config.custom_segment,
      );
      if (!customSegmentConfig) {
        return result;
      }
      const edges = get(customSegmentConfig, 'edges', []);
      const userSelectedNode = getUserSelectedNodesForCustomSegment(
        membership.segment_selections ?? [],
        customSegment,
      );
      const segmentMatchWithMap = generateNodesPerEdge({
        edges,
        nodes: customSegment.nodes ?? [],
      });
      const nodesForUserSelectedNode: GroupCustomSegmentNode[] =
        (userSelectedNode && get(segmentMatchWithMap, userSelectedNode)) || [];
      const nodesToMatchWith = uniq(
        nodesForUserSelectedNode.map((node) => node.name),
      );
      const nodes =
        'preferred' === customSegmentConfig?.match_type
          ? (customSegment.nodes?.map((node) => node.name) ?? [])
          : nodesToMatchWith;
      const excludedCustomSegmentNodes = customSegmentExclusions
        .map((exclusion) => exclusion.exclude_string)
        .filter(isString);
      if (isEmpty(nodes)) {
        return result;
      }
      return [
        ...result,
        {
          nodes,
          selectedNodes: difference(nodes, excludedCustomSegmentNodes),
          title: customSegment.title,
        },
      ];
    }, []);
}

export function findRoleInAccountMatchExclusions(
  matchExclusions: MatchExclusion[],
  role: string,
): MatchExclusion | undefined {
  const roleMatchExclusions = getMatchExclusionsByType(
    matchExclusions,
    'match_segment',
  );
  return find(
    roleMatchExclusions,
    (exclusion) => role === exclusion.exclude_string,
  );
}

export function findCustomSegmentNodeInAccountMatchExclusions(
  matchExclusions: MatchExclusion[],
  customSegmentId: string,
  customSegmentNode: string,
): MatchExclusion | undefined {
  const customSegmentMatchExclusions = getMatchExclusionsByType(
    matchExclusions,
    'custom_segment',
  );
  return find(
    customSegmentMatchExclusions,
    (exclusion) =>
      customSegmentId === exclusion.exclude_id &&
      customSegmentNode === exclusion.exclude_string,
  );
}

export function getMatchExclusionsByType(
  matchExclusions: MatchExclusion[],
  type: string,
): MatchExclusion[] {
  return matchExclusions?.filter(
    (exclusion) => type === exclusion.exclusion_type,
  );
}

export function getRoleNamesFromSelections(
  roleSelections: RoleSelections,
  selected: boolean,
): string[] {
  return keys(pickBy(roleSelections, (isSelected) => selected === isSelected));
}

export function getCustomSegmentsFromSelections(
  customSegmentsSelections: CustomSegmentsSelections,
  selected: boolean,
): { [customSegmentId: string]: string[] } {
  return mapValues(customSegmentsSelections, (customSegmentSelections) =>
    keys(
      pickBy(customSegmentSelections, (isSelected) => selected === isSelected),
    ),
  );
}

export function getMatchExclusionsUpdate(
  matchExclusions: MatchExclusion[],
  selections: MatchSelections,
) {
  const idExclusions = getMatchExclusionsByType(matchExclusions, '_id');
  const emailExclusions = getMatchExclusionsByType(matchExclusions, 'email');
  const roleExclusions = buildMatchExclusionsForRoles(selections.roles);
  const customSegmentsExclusions = buildMatchExclusionsForCustomSegments(
    selections.customSegments,
  );
  const hiddenCustomSegmentsExclusions = buildMatchExclusionsForCustomSegments(
    selections.hiddenCustomSegments,
  );
  return {
    match_exclusions: [
      ...idExclusions,
      ...emailExclusions,
      ...roleExclusions,
      ...customSegmentsExclusions,
      ...hiddenCustomSegmentsExclusions,
    ],
  };
}

export function buildMatchExclusionsForCustomSegments(
  customSegmentsSelections: CustomSegmentsSelections,
) {
  const excludedCustomSegments = getCustomSegmentsFromSelections(
    customSegmentsSelections,
    false,
  );
  return reduce(
    excludedCustomSegments,
    (result, nodeSelections, customSegmentId) => {
      return [
        ...result,
        ...nodeSelections.map((node) => ({
          exclude_id: customSegmentId,
          exclude_string: node,
          exclusion_type: 'custom_segment',
        })),
      ];
    },
    [],
  );
}

export function buildMatchExclusionsForRoles(roleSelections: RoleSelections) {
  const roleNames = getRoleNamesFromSelections(roleSelections, false);
  return roleNames.map((roleName) => ({
    exclude_string: roleName,
    exclusion_type: 'match_segment',
  }));
}
