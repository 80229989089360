import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ToastModule } from '@libs/src/toast/toast.module';

import { UserEffects } from '@libs/src/user/user.effects';
import { userReducer } from '@libs/src/user/user.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([UserEffects]),
    StoreModule.forFeature('user', userReducer),
    ToastModule,
  ],
  providers: [UserEffects],
})
export class UserStoreModule {}
