import {
  TENANT_PATCH_TYPE,
  TENANT_SET_TYPE,
  TenantAction,
} from './tenant.actions';

import merge from 'lodash-es/merge';

const INITIAL_STATE = {};

export function tenantReducer(
  state: any = INITIAL_STATE,
  action: TenantAction,
) {
  switch (action.type) {
    case TENANT_SET_TYPE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case TENANT_PATCH_TYPE: {
      return merge({}, state, action.payload);
    }

    default: {
      return state;
    }
  }
}
