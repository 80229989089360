import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AuthService } from '@main-client/src/app/auth/auth.service';

import {
  Account,
  AccountGroupMembership,
} from '@libs/src/models/account.model';
import { Group } from '@libs/src/models/group.model';
import { SetUser } from '@libs/src/user/user.actions';

import { IAppState } from '../app.state';

import filter from 'lodash-es/filter';
import isEqual from 'lodash-es/isEqual';
import keys from 'lodash-es/keys';
import pick from 'lodash-es/pick';

const ACCOUNT_API_URL = '/api/account';
const ACCOUNT_API_V2_URL = '/api/v2/account';
const UPDATE_BLOCKED_LIST_API_URL = '/api/account/update-blocked-list';
const AccountGroupApiUrl = (group: Group) =>
  `/api/v2/account/groups/${group._id}`;

export interface IAccountPatchResponse {
  data: any;
  message: string;
}

@Injectable({ providedIn: 'root' })
export class UserMutationService {
  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<IAppState>,
    private readonly authService: AuthService,
  ) {}

  patchUser(update: any): Observable<any> {
    return this.http.patch(ACCOUNT_API_URL, update).pipe(
      map((response: IAccountPatchResponse) => {
        return {
          ...update,
          ...pick(response.data, keys(update)),
        };
      }),
      tap((updatedValues: any) => this._dispatchUserChange(updatedValues)),
    );
  }

  deleteSession(sessions: any[], sessionId: string): Observable<any> {
    const url = `${ACCOUNT_API_V2_URL}/sessions/${sessionId}`;
    return this.http.delete(url).pipe(
      map(() => ({
        sessions: filter(
          sessions,
          ({ _id }: { _id: any }) => !isEqual(sessionId, _id),
        ),
      })),
      tap((userUpdate: any) => this._dispatchUserChange(userUpdate)),
    );
  }

  updateUserGroupAssociation(groupId: string, update: any): Observable<any> {
    const url = `${ACCOUNT_API_V2_URL}/groups/${groupId}`;
    return this.http
      .put<{ groups: AccountGroupMembership[] }>(url, update)
      .pipe(
        map((userGroups) => ({ groups: userGroups })),
        tap((userUpdate: any) => this._dispatchUserChange(userUpdate)),
      );
  }

  deleteProfile(callback: () => void): Observable<any> {
    return this.http
      .delete(ACCOUNT_API_V2_URL)
      .pipe(tap(() => this.authService.logout(callback)));
  }

  private _dispatchUserChange(user: any) {
    this.store.dispatch(SetUser(user));
  }

  updateGroupMembership(group: Group, update: any) {
    return this.http.put(AccountGroupApiUrl(group), update);
  }

  deleteGroupMembership(group: Group) {
    return this.http.delete(AccountGroupApiUrl(group));
  }

  public blockUser(userId: string): Observable<any> {
    return this.http
      .post(UPDATE_BLOCKED_LIST_API_URL, { read: false, userId })
      .pipe(
        map(({ blockedList }: { blockedList: string[] }) => ({
          blocked_list: blockedList,
        })),
        tap((userUpdate: Account) => this._dispatchUserChange(userUpdate)),
        catchError(() => EMPTY),
      );
  }

  public unblockUser(userId: string): Observable<any> {
    return this.http
      .post(UPDATE_BLOCKED_LIST_API_URL, { remove: true, userId })
      .pipe(
        map(({ blockedList }: { blockedList: string[] }) => ({
          blocked_list: blockedList,
        })),
        tap((userUpdate: Account) => this._dispatchUserChange(userUpdate)),
        catchError(() => EMPTY),
      );
  }

  sendPendingEmail(update: any): Observable<any> {
    return this.http.post(`${ACCOUNT_API_URL}/send-pending-email`, update);
  }
}
