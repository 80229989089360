import {
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { tap } from 'rxjs';

const API_REST_PREFIX_REGEX = new RegExp('\\/api\\/(?!graphql)');
const HTTP_GET_METHOD = 'GET';

@Injectable({ providedIn: 'root' })
export class GraphqlRequestInterceptor implements HttpInterceptor {
  constructor(private readonly apollo: Apollo) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (
      HTTP_GET_METHOD !== request.method &&
      API_REST_PREFIX_REGEX.test(request.url)
    ) {
      return next.handle(request).pipe(
        tap((httpEvent) => {
          if (HttpEventType.Response !== httpEvent.type) {
            return;
          }
          const evicted = this.apollo.client.cache.evict({});
          if (evicted) {
            this.apollo.client.cache.gc();
          }
        }),
      );
    }
    return next.handle(request);
  }
}
