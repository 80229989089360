import {
  APP_NAME_HEADER_KEY,
  APP_VERSION,
  APP_VERSION_HEADER_KEY,
} from '@ttc/ttc-constants/app.metadata';

import type { StackDriverError } from '@libs/src/global-error-handler/stack-drive-error';
import { APP_NAME } from '@main-client/src/app/app.metadata';

export async function sendErrorReport(errorReport: StackDriverError) {
  try {
    await fetch('/__errors', {
      body: JSON.stringify({
        referrer: window.document.referrer,
        url: window.location.href,
        userAgent: window.navigator.userAgent,
        ...errorReport,
      }),
      headers: {
        [APP_NAME_HEADER_KEY]: APP_NAME,
        [APP_VERSION_HEADER_KEY]: APP_VERSION,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
  } catch (error) {
    console.error(error);
  }
}

function onError(event: ErrorEvent) {
  const message = event.error?.stack ?? `${event.error}`;
  sendErrorReport({
    message,
    context: { nonAngularError: true },
  });
}

function onUnhandledRejection(event: PromiseRejectionEvent) {
  const message = event.reason?.stack ?? `${event.reason}`;
  sendErrorReport({
    message,
    context: { nonAngularError: true, promise: event.promise },
  });
}

export function addNonAngularErrorHandlers() {
  window.addEventListener('error', onError);
  window.addEventListener('unhandledrejection', onUnhandledRejection);
}

export function removeNonAngularErrorHandlers() {
  window.removeEventListener('error', onError);
  window.removeEventListener('unhandledrejection', onUnhandledRejection);
}
