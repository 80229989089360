import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RouteLoadingIndicatorComponent } from '@libs/src/route-loading-indictator/route-loading-indicator.component';

@NgModule({
  imports: [CommonModule],
  exports: [RouteLoadingIndicatorComponent],
  declarations: [RouteLoadingIndicatorComponent],
})
export class RouteLoadingIndicatorModule {}
