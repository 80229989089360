import { HubsActions } from './hubs.actions';
import {
  GET_HUBS_SUCCESS_TYPE,
  GET_HUBS_TYPE,
  REMOVE_HUBS_FILTER_TYPE,
  RESET_HUBS_TYPE,
  SET_HUBS_FILTERS_TYPE,
  SET_HUBS_KEYWORD_TYPE,
} from './hubs.actions';
import { Group } from '@libs/src/models/group.model';

import { HubsState } from '../app.state';

import findIndex from 'lodash-es/findIndex';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import pickBy from 'lodash-es/pickBy';

const DEFAULT_ITEMS: Group[] = [];

const DEFAULT_STATE: HubsState = {
  items: [],
  keyword: '',
  totalCount: 0,
};

export function hubsReducer(
  state: HubsState = DEFAULT_STATE,
  action: HubsActions,
) {
  switch (action.type) {
    case GET_HUBS_TYPE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_HUBS_SUCCESS_TYPE: {
      const items = [...state.items];
      action.payload.items.forEach((group: Group) => {
        const existingGroupIndex = findIndex(state.items, (item: Group) =>
          isEqual(item._id, group._id),
        );
        if (0 > existingGroupIndex) {
          items.push(group);
        } else {
          items[existingGroupIndex] = {
            ...items[existingGroupIndex],
            ...group,
          };
        }
      });
      return {
        ...state,
        items,
        totalCount: action.payload.totalCount,
        isLoading: false,
      };
    }

    case SET_HUBS_FILTERS_TYPE: {
      return {
        ...state,
        items: [...DEFAULT_ITEMS],
        isLoading: true,
        searchFilters: action.payload,
      };
    }

    case REMOVE_HUBS_FILTER_TYPE: {
      const [filterName, filterValue] = (action.payload as string).split('.');
      const currentFilerValue = state.searchFilters[filterName];
      const updatedFilterForFilterName = Array.isArray(currentFilerValue)
        ? currentFilerValue.filter((hubFilter) => hubFilter !== filterValue)
        : undefined;
      return {
        ...state,
        items: [...DEFAULT_ITEMS],
        isLoading: true,
        searchFilters: pickBy(
          {
            ...state.searchFilters,
            [filterName]: updatedFilterForFilterName,
          },
          (hubFilter) => !isEmpty(hubFilter),
        ),
      };
    }

    case RESET_HUBS_TYPE: {
      return {
        ...state,
        items: [...DEFAULT_ITEMS],
      };
    }

    case SET_HUBS_KEYWORD_TYPE: {
      return {
        ...state,
        isLoading: true,
        items: [...DEFAULT_ITEMS],
        keyword: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
