<div
  [ngClass]="'u-padding-0-8'"
  [ngClass.gt-sm]="'u-padding-0-24'"
  class="c-header"
  fxLayout="row"
  fxLayoutAlign="start center"
>
  <div [fxShow]="true" [fxShow.gt-sm]="false" fxFlex="48px">
    <a
      (click)="showSideNav(drawer)"
      aria-label="Menu"
      mat-icon-button
      i18n-aria-label="@@headerView.menuLabel"
    >
      <i class="material-icons"> menu </i>
    </a>
  </div>
  <div
    [ngStyle]="{ height: '32px', width: '32px' }"
    class="u-marginRight-16"
    fxFlex="32px"
  >
    <a
      [ngStyle]="{ height: '32px', width: '32px' }"
      aria-label="Go to homepage"
      class="u-display-block"
      routerLink="/"
      i18n-aria-label="@@headerView.goToHomepageLabel"
    >
      @if (tenant?.name) {
        <app-avatar-tenant [item]="tenant" class="u-display-block">
        </app-avatar-tenant>
      }
    </a>
  </div>
  <div
    class="text-subtitle-1"
    fxFlex="auto"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <!-- TODO(merci): revert previous implementation when route titles are localized https://github.com/tenthousandcoffees/web-client/issues/7888 -->
    @if (!tenant?.hidden_ui_items?.header_tenant_name) {
      <a class="u-marginRight-16" routerLink="/">
        {{ tenant?.friendly_name }}
      </a>
    }
    @if (title) {
      <div [fxShow]="false">
        {{ title }}
      </div>
    }
  </div>
  @if (authService.$isLoggedIn()) {
    @if (user) {
      <div
        [fxHide]="true"
        [fxHide.gt-sm]="false"
        fxFlex="none"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        @if (user.isAdmin) {
          <a
            [ngStyle]="{ margin: '0 10px 0 0' }"
            class="u-display-block c-headerLinkItem color-grey"
            mat-button
            routerLink="/admin"
            routerLinkActive="mat-primary"
          >
            <p [ngStyle]="{ height: '18px' }" i18n="@@headerView.adminLabel">
              Admin
            </p>
          </a>
        }
        @if (tenant?.enabled_features?.conversations) {
          <button
            i18n-matTooltip="@@headerView.conversationsLabel"
            (click)="resetUnseenCount('conversations')"
            aria-label="Conversations"
            class="u-display-block c-headerLinkItem u-position-relative"
            mat-icon-button
            matTooltip="Conversations"
            routerLink="/conversations"
            routerLinkActive="mat-primary"
            i18n-aria-label="@@headerView.conversationsLabel"
          >
            <mat-icon
              [matBadge]="(header | async).unseenConversationsCount"
              [matBadgeHidden]="!(header | async).unseenConversationsCount"
              [ngStyle]="{ verticalAlign: '0' }"
              class="md-18"
              fontSet="material-symbols-outlined"
              matBadgeColor="warn"
              matBadgeSize="small"
            >
              chat
            </mat-icon>
          </button>
        }
        <button
          i18n-matTooltip="@@headerView.notificationsLabel"
          [ngClass]="{
            'has-notification': (header | async).unseenNotificationsCount,
          }"
          (click)="resetUnseenCount('notifications')"
          aria-label="Notifications"
          class="u-display-block c-headerLinkItem u-position-relative"
          mat-icon-button
          matTooltip="Notifications"
          routerLink="/notifications"
          routerLinkActive="mat-primary"
          i18n-aria-label="@@headerView.notificationsLabel"
        >
          <mat-icon
            [matBadge]="(header | async).unseenNotificationsCount"
            [matBadgeHidden]="!(header | async).unseenNotificationsCount"
            [ngStyle]="{ verticalAlign: '0' }"
            class="md-18"
            fontSet="material-symbols-outlined"
            matBadgeColor="warn"
            matBadgeSize="small"
          >
            notifications
          </mat-icon>
        </button>
        @if (tenant?.enabled_features?.localization) {
          <button
            (click)="openLanguageManager()"
            class="u-marginRight-16"
            mat-icon-button
            matTooltip="Languages"
            i18n-matTooltip="@@global.languages"
          >
            <mat-icon [ngStyle]="{ verticalAlign: '0' }" class="md-18"
              >language</mat-icon
            >
          </button>
        }
        <button
          [matMenuTriggerFor]="menu"
          [ngStyle]="{ height: '32px', width: '32px' }"
          aria-label="Open profile menu"
          class="c-button"
          i18n-aria-label="@@headerView.openProfileMenuLabel"
        >
          @if (user._id) {
            <app-avatar-account [item]="user" class="u-display-block">
            </app-avatar-account>
          }
        </button>
        <mat-menu #menu="matMenu" [overlapTrigger]="false">
          <div [ngStyle]="{ 'min-width': '170px' }">
            <button
              i18n="@@headerView.profileLabel"
              [routerLink]="['/profile', user?.slug]"
              mat-menu-item
            >
              Profile
            </button>
            <button
              (click)="showZendesk()"
              mat-menu-item
              i18n="@@headerView.askForHelpLabel"
            >
              Ask for help
            </button>
            <button
              (click)="helpCenterClicked()"
              mat-menu-item
              i18n="@@headerView.helpCenterLabel"
            >
              Help center
            </button>
            <button
              i18n="@@global.settings"
              [routerLink]="['/settings']"
              mat-menu-item
            >
              Settings
            </button>
            <button
              i18n="@@headerView.logOutLabel"
              (click)="logOut()"
              mat-menu-item
            >
              Log out
            </button>
          </div>
        </mat-menu>
      </div>
    }
  }
</div>
<mat-sidenav-container
  [ngStyle]="{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    'z-index': 1000,
    'pointer-events': showDrawer ? 'initial' : 'none',
    opacity: showDrawer ? '1' : '0',
  }"
  class="bg-color-teal-50"
>
  <mat-sidenav
    #drawer
    [ngStyle]="{ width: '250px' }"
    (openedChange)="drawerOpenedChange($event)"
    class="u-padding-24-0"
  >
    <div class="u-padding-0-16">
      <app-avatar-account [item]="user" class="u-display-block">
      </app-avatar-account>
    </div>
    @if (routes?.length) {
      <mat-nav-list>
        @for (routeConfig of routes; track routeConfig) {
          <a
            [routerLink]="routeConfig.routerLink"
            (click)="drawer.close()"
            class="u-display-block"
            mat-list-item
          >
            {{ routeConfig.title }}
          </a>
        }
      </mat-nav-list>
      <mat-divider></mat-divider>
    }
    @if (user) {
      <mat-nav-list>
        @if (currentMembership) {
          @if (currentMembership?.document; as group) {
            <a
              [ngStyle]="{
                'background-color': (group?.background_color)[0],
                color: (group?.background_color)[0] | appMostReadableColor,
              }"
              [routerLink]="['/hub', group?.slug]"
              (click)="drawer.close()"
              mat-list-item
            >
              {{ group?.name }}
            </a>
          }
        }
        <a
          i18n="@@headerView.profileLabel"
          [routerLink]="['/profile', user.slug]"
          (click)="drawer.close()"
          mat-list-item
        >
          Profile
        </a>
        <a
          i18n="@@headerView.conversationsLabel"
          [routerLink]="['/conversations']"
          (click)="drawer.close()"
          mat-list-item
        >
          Conversations
        </a>
        <a
          i18n="@@headerView.notificationsLabel"
          [routerLink]="['/notifications']"
          (click)="drawer.close()"
          mat-list-item
        >
          Notifications
        </a>
        <a
          (click)="drawer.close()"
          appZendeskLink
          i18n="@@headerView.helpCenterLabel"
          href="https://support.tenthousandcoffees.com"
          mat-list-item
        >
          Help Center
        </a>
        @if (user.isAdmin) {
          <a
            i18n="@@headerView.adminLabel"
            [routerLink]="['/admin']"
            (click)="drawer.close()"
            mat-list-item
          >
            Admin
          </a>
        }
        @if (tenant?.enabled_features?.localization) {
          <button
            (click)="openLanguageManager()"
            class="c-button"
            mat-list-item
            i18n="@@header.languageLabel"
          >
            Language
          </button>
        }
        <a
          i18n="@@global.settings"
          [routerLink]="['/settings']"
          (click)="drawer.close()"
          mat-list-item
        >
          Settings
        </a>
        <a
          i18n="@@headerView.logOutLabel"
          (click)="logOut(); drawer.close()"
          mat-list-item
        >
          Log Out
        </a>
      </mat-nav-list>
    }
  </mat-sidenav>
</mat-sidenav-container>
