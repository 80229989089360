<div
  *ngIf="isRouterLoading"
  [ngStyle]="{ top: '0', left: '0', right: '0', 'z-index': 1000 }"
  class="u-position-fixed"
>
  <div class="c-loadingBar">
    <div class="c-loadingBar-background">
      <div class="c-loadingBar-progress"></div>
    </div>
  </div>
</div>
