import {
  HEADER_HIDE_TYPE,
  HEADER_RESET_UNSEEN_CONVERSATIONS_COUNT_TYPE,
  HEADER_RESET_UNSEEN_NOTIFICATIONS_COUNT_TYPE,
  HEADER_SET_UNSEEN_CONVERSATIONS_COUNT_TYPE,
  HEADER_SET_UNSEEN_NOTIFICATIONS_COUNT_TYPE,
  HEADER_SHOW_TYPE,
  HeaderAction,
} from './header.actions';

import { IHeaderState } from '../app.state';

const INITIAL_STATE: IHeaderState = {
  unseenConversationsCount: 0,
  unseenNotificationsCount: 0,
  show: true,
};

export function headerReducer(
  state: any = INITIAL_STATE,
  action: HeaderAction,
) {
  switch (action.type) {
    case HEADER_HIDE_TYPE: {
      return {
        ...state,
        ...{ show: false },
      };
    }

    case HEADER_RESET_UNSEEN_CONVERSATIONS_COUNT_TYPE: {
      return {
        ...state,
        ...{ unseenConversationsCount: 0 },
      };
    }

    case HEADER_RESET_UNSEEN_NOTIFICATIONS_COUNT_TYPE: {
      return {
        ...state,
        ...{ unseenNotificationsCount: 0 },
      };
    }

    case HEADER_SET_UNSEEN_CONVERSATIONS_COUNT_TYPE: {
      return {
        ...state,
        ...{ unseenConversationsCount: action.payload },
      };
    }

    case HEADER_SET_UNSEEN_NOTIFICATIONS_COUNT_TYPE: {
      return {
        ...state,
        ...{ unseenNotificationsCount: action.payload },
      };
    }

    case HEADER_SHOW_TYPE: {
      return {
        ...state,
        ...{ show: true },
      };
    }

    default: {
      return state;
    }
  }
}
