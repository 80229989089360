import { Action } from '@ngrx/store';

import { AccountGroupMembership } from '@libs/src/models/account.model';
import { Group } from '@libs/src/models/group.model';

export const USER_CLEAR_TYPE = '[user] Clear';
export const USER_SET_SINGLE_GROUP_TYPE = '[user] Set Single Group';
export const USER_SET_SINGLE_MEMBERSHIP_TYPE = '[user] Set Single Membership';
export const USER_SET_TYPE = '[user] Set';
export const USER_INIT_SAVE_TYPE = '[user] Init Save';
export const USER_UPDATE_GROUP_TYPE = '[user] Update Group';
export const USER_UPDATE_PENDING_GROUP_TYPE = '[user] Update Pending Group';

export class UserAction implements Action {
  constructor(
    public type: string = '',
    public payload: any = {},
  ) {}
}

export function SetUserSingleGroup(payload: { group: Group }): UserAction {
  return new UserAction(USER_SET_SINGLE_GROUP_TYPE, payload);
}

export function SetUserSingleMembership(payload: {
  membership: AccountGroupMembership;
}): UserAction {
  return new UserAction(USER_SET_SINGLE_MEMBERSHIP_TYPE, payload);
}

export function SetUser(payload: any): UserAction {
  return new UserAction(USER_SET_TYPE, payload);
}

export function UserInitSave(payload: any): UserAction {
  return new UserAction(USER_INIT_SAVE_TYPE, payload);
}

export function UpdateUserGroup(payload: any): UserAction {
  return new UserAction(USER_UPDATE_GROUP_TYPE, payload);
}

export function UpdateUserPendingGroup(payload: any): UserAction {
  return new UserAction(USER_UPDATE_PENDING_GROUP_TYPE, payload);
}
