import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { AvatarAccountComponent } from './avatar-account/avatar-account.component';
import { AvatarTenantComponent } from './avatar-tenant/avatar-tenant.component';
import { AvatarComponent } from './avatar.component';
import { AvatarAccountColoredBorderComponent } from '@libs/src/avatar/avatar-account-colored-border/avatar-account-colored-border.component';

@NgModule({
  declarations: [
    AvatarAccountComponent,
    AvatarAccountColoredBorderComponent,
    AvatarComponent,
    AvatarTenantComponent,
  ],
  exports: [
    AvatarAccountComponent,
    AvatarAccountColoredBorderComponent,
    AvatarComponent,
    AvatarTenantComponent,
  ],
  imports: [CommonModule, FlexLayoutModule, MatIconModule],
})
export class AvatarModule {}
