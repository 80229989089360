import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { EMPTY } from 'rxjs';
import { fromError } from 'stacktrace-js';

import { LoggerService } from '@libs/src/logger/logger.service';

const chunkLoadingErrorMessageRegex = /Loading chunk [\d]+ failed/i;
const firefoxQuotaExceededError = {
  code: 1014,
  name: 'NS_ERROR_DOM_QUOTA_REACHED',
};
const quotaExceededErrorCode = 22;
const ABORTED = Symbol('ABORTED');

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  abortController = new AbortController();

  constructor(private readonly injector: Injector) {
    window.addEventListener('beforeunload', () => {
      this.abortController.abort();
    });
  }

  handleError(error: Error) {
    if (this.isQuotaExceededError(error)) {
      return EMPTY;
    }

    if (this.isChunkLoadingError(error.message || error.toString())) {
      window.location.reload();
      return EMPTY;
    }
    this.logError(error);
    throw error;
  }

  logError(
    error: Error & { column?: number; file?: string; line?: number },
  ): void {
    let errorMessage: string;
    if ('string' === typeof error.message) {
      errorMessage = `${error.name ?? 'Error'}: ${error.message}`;
    } else {
      errorMessage = error.toString();
    }
    const loggerService = this.injector.get(LoggerService);
    if (error instanceof HttpErrorResponse) {
      loggerService.logError({
        context: {
          response: error.error,
          status: error.status,
          url: error.url,
        },
        message: errorMessage,
      });
    } else if (!error.stack) {
      loggerService.logError({ message: errorMessage });
    } else if (this.abortController.signal.aborted) {
      loggerService.logError({
        context: { isWindowReloading: this.abortController.signal.aborted },
        message: error.stack,
      });
    } else {
      this.fromError(error)
        .then((stackframes) => {
          const stackString = stackframes
            .splice(0, 5)
            .map((stackframe) =>
              [
                '    at ',
                stackframe.functionName || '<anonymous>',
                '(',
                `${stackframe.fileName}:`,
                `${stackframe.lineNumber}:`,
                stackframe.columnNumber,
                ')',
              ].join(''),
            )
            .join('\n');
          loggerService.logError({
            message: `${errorMessage}\n${stackString}`,
          });
        })
        .catch((reason) => {
          if (reason === ABORTED) {
            loggerService.logError({
              context: {
                isWindowReloading: this.abortController.signal.aborted,
              },
              message: error.stack,
            });
            return;
          }
          const message = [
            'Error extracting stack trace: ',
            reason,
            '\n',
            errorMessage,
            '\n',
            `    (${error.file}:${error.line}:${error.column})`,
          ].join('');
          loggerService.logError({ message });
        });
    }
  }

  isQuotaExceededError(error?: Error & { code?: number }) {
    if (!error) {
      return false;
    }

    const errorCode = error.code;
    if (!errorCode) {
      return false;
    }

    const isFirefoxError =
      firefoxQuotaExceededError.code === errorCode &&
      firefoxQuotaExceededError.name === error.name;
    return quotaExceededErrorCode === errorCode || isFirefoxError;
  }

  isChunkLoadingError(errorMessage: string) {
    return chunkLoadingErrorMessageRegex.test(errorMessage);
  }

  fromError(error: Error) {
    const parsedStack = fromError(error);
    const abortPromise = new Promise<never>((resolve, reject) => {
      if (this.abortController.signal.aborted) {
        reject(ABORTED);
        return;
      }
      const abortListener = () => {
        this.abortController.signal.removeEventListener('abort', abortListener);
        reject(ABORTED);
      };
      this.abortController.signal.addEventListener('abort', abortListener);
      parsedStack.finally(() => {
        this.abortController.signal.removeEventListener('abort', abortListener);
      });
    });
    return Promise.race([parsedStack, abortPromise]);
  }
}
