import { ApolloLink } from '@apollo/client/core';

const GRAPHQL_ERROR_CODE_FORBIDDEN = 'FORBIDDEN';

export const transformPermissionErrors = new ApolloLink((operation, forward) =>
  forward(operation).map((data) => {
    if (
      !data.data ||
      !data.errors?.every(
        (error) => GRAPHQL_ERROR_CODE_FORBIDDEN === error.extensions?.code,
      )
    ) {
      return data;
    }
    return {
      ...data,
      errors: undefined,
      extensions: {
        ...data.extensions,
        permissionErrors: data.errors,
      },
    };
  }),
);
