import { HttpErrorResponse } from '@angular/common/http';
import { ApolloError, isApolloError } from '@apollo/client/errors';

import isNil from 'lodash-es/isNil';

export function getServerError(responseError: ApolloError | HttpErrorResponse) {
  if (isNil(responseError)) {
    return responseError;
  }
  if (isApolloError(responseError)) {
    return responseError.message;
  }
  return (
    responseError.error?.message || responseError.error || responseError.message
  );
}
