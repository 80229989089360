import {
  SIDENAV_HIDE_TYPE,
  SIDENAV_SHOW_TYPE,
  SideNavAction,
} from './group.actions';

import { ISideNavState } from '../app.state';

const INITIAL_STATE: ISideNavState = {
  show: true,
};

export function sideNavReducer(
  state: ISideNavState = INITIAL_STATE,
  action: SideNavAction,
) {
  switch (action.type) {
    case SIDENAV_HIDE_TYPE: {
      return {
        ...state,
        show: false,
      };
    }
    case SIDENAV_SHOW_TYPE: {
      return {
        ...state,
        show: true,
      };
    }
    default: {
      return state;
    }
  }
}
