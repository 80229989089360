import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  DEFAULT_LOCALE_CODE,
  SupportedLocaleCode,
} from '@ttc/ttc-constants/locale';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, finalize, take, tap } from 'rxjs/operators';

import { ToastService } from '@libs/src/toast/toast.service';
import { LocaleManagerService } from '@main-client/src/app/locale-manager/locale-manager.service';
import { ContentLocalizationService } from '@main-client/src/app/translate/content-localization.service';

import { getServerError } from '@libs/src/error-handler/error-handler.helper';
import { Memoize } from '@libs/src/functions/memoize';
import { ContentLocalization } from '@libs/src/locale/locale.interface';
import { IAppState } from '@main-client/src/app/app.state';

import isEmpty from 'lodash-es/isEmpty';

@Injectable({ providedIn: 'root' })
export class TranslateService {
  userState = this.store.select('user').pipe(
    filter((user) => !isEmpty(user)),
    take(1),
  );
  currentUserLocale: SupportedLocaleCode;
  $translationsInProgress = signal(0);
  $liveTranslationsInView = signal<ContentLocalization[]>([]);
  $isUserUsingDefaultLocale = signal<boolean>(true);

  constructor(
    private readonly contentLocalizationService: ContentLocalizationService,
    private readonly localeManagerService: LocaleManagerService,
    private readonly store: Store<IAppState>,
    private readonly toastService: ToastService,
  ) {
    this.userState.subscribe((user) => {
      this.currentUserLocale = this.localeManagerService.getLocaleForUser(user);
      this.$isUserUsingDefaultLocale.set(
        this.currentUserLocale === DEFAULT_LOCALE_CODE,
      );
    });
  }

  getCurrentUserLocale(): SupportedLocaleCode {
    return this.currentUserLocale;
  }

  addToTranslationsInProgress() {
    this.$translationsInProgress.update((value) => value + 1);
  }

  removeFromTranslationsInProgress() {
    this.$translationsInProgress.update((value) => value - 1);
  }

  @Memoize()
  translate(
    contentLocalization: Partial<ContentLocalization>,
  ): Observable<ContentLocalization> {
    this.addToTranslationsInProgress();
    return this.contentLocalizationService
      .getContentLocalization(contentLocalization)
      .pipe(
        tap((result) => {
          this.addToLiveTranslationsInView(result);
        }),
        catchError((response: HttpErrorResponse) => {
          this.toastService.showError(getServerError(response));
          return EMPTY;
        }),
        finalize(() => {
          this.removeFromTranslationsInProgress();
        }),
      );
  }

  liveTranslate(
    untranslatedItem: Partial<ContentLocalization>,
    liveTranslatePreference: boolean,
  ): Observable<ContentLocalization> {
    untranslatedItem.target_locale_code = this.currentUserLocale;
    const existingTranslation =
      this.getFromTranslationsInView(untranslatedItem);
    if (!liveTranslatePreference && existingTranslation) {
      return of(existingTranslation);
    }
    return this.translate(untranslatedItem);
  }

  addToLiveTranslationsInView(contentLocalization: ContentLocalization): void {
    this.$liveTranslationsInView.update((items) =>
      items.find((item) => item.source === contentLocalization.source)
        ? items
        : [...items, contentLocalization],
    );
  }

  removeFromLiveTranslationsInView(
    contentLocalization: Partial<ContentLocalization>,
  ): void {
    this.$liveTranslationsInView.update((items) =>
      items.filter((item) => item.source !== contentLocalization.source),
    );
  }

  getFromTranslationsInView(
    contentLocalization: Partial<ContentLocalization>,
  ): ContentLocalization {
    return this.$liveTranslationsInView().find(
      (item) => item.source === contentLocalization.source,
    );
  }
}
