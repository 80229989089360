import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval, map, Observable } from 'rxjs';

@Component({
  selector: 'app-user-idle-timeout-dialog',
  templateUrl: './user-idle-timeout-dialog.component.html',
})
export class UserIdleTimeoutDialogComponent implements OnInit {
  countdown: Observable<number>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { timeout: number },
  ) {}

  ngOnInit() {
    this.countdown = interval(1000).pipe(
      map(() => Math.floor((this.data.timeout - Date.now()) / 1000)),
    );
  }
}
