import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ContentLocalization,
  ContentLocalizationResponse,
  GroupLocaleResponse,
  GroupLocalesResponse,
} from '@libs/src/locale/locale.interface';
import { Group, GroupLocale } from '@libs/src/models/group.model';

const GROUPS_V2_API_URL = '/api/v2/groups';

@Injectable({ providedIn: 'root' })
export class LocaleBackend {
  constructor(private readonly http: HttpClient) {}

  addGroupLocale(
    groupId: Group['_id'],
    groupLocale: Partial<GroupLocale>,
  ): Observable<GroupLocaleResponse> {
    return this.http.post<GroupLocaleResponse>(
      `${GROUPS_V2_API_URL}/${groupId}/locales`,
      groupLocale,
    );
  }

  deleteTranslation(
    groupId: Group['_id'],
    localeId: GroupLocale['_id'],
    mappingKey: string,
  ): Observable<GroupLocaleResponse> {
    return this.http.delete<GroupLocaleResponse>(
      `${GROUPS_V2_API_URL}/${groupId}/locales/${localeId}/mappings/?key=${mappingKey}`,
    );
  }
  getGroupLocales(
    group: Group,
    params?: {
      _id?: GroupLocale['_id'];
      locale_name?: GroupLocale['locale_name'];
      translation_unit?: GroupLocale['translation_unit'];
    },
  ): Observable<GroupLocalesResponse> {
    return this.http.get<GroupLocalesResponse>(
      `${GROUPS_V2_API_URL}/${group._id || group.slug}/locales`,
      { params },
    );
  }

  updateGroupLocale(
    groupId: Group['_id'],
    localeId: GroupLocale['_id'],
    update: Partial<GroupLocale>,
  ): Observable<GroupLocaleResponse> {
    return this.http.patch<GroupLocaleResponse>(
      `${GROUPS_V2_API_URL}/${groupId}/locales/${localeId}`,
      update,
    );
  }

  updateGroupLocaleTranslation(
    groupId: Group['_id'],
    localeId: GroupLocale['_id'],
    mapping: { key: string; value: string },
  ): Observable<GroupLocaleResponse> {
    return this.http.patch<GroupLocaleResponse>(
      `${GROUPS_V2_API_URL}/${groupId}/locales/${localeId}/mappings`,
      mapping,
    );
  }

  translateContent(
    contentLocalization: Partial<ContentLocalization>,
  ): Observable<ContentLocalizationResponse> {
    const url = `${GROUPS_V2_API_URL}/${contentLocalization.group}/translate-content`;
    return this.http.post<{ contentLocalization: ContentLocalization }>(
      url,
      contentLocalization,
    );
  }

  updateContentLocalization(contentLocalization: Partial<ContentLocalization>) {
    const url = `${GROUPS_V2_API_URL}/${contentLocalization.group}/translate-content`;
    return this.http.patch<ContentLocalizationResponse>(
      url,
      contentLocalization,
    );
  }
}
