import { Pipe, PipeTransform } from '@angular/core';
import { mostReadable } from '@ctrl/tinycolor';

@Pipe({
  name: 'appMostReadableColor',
})
export class MostReadableColorPipe implements PipeTransform {
  transform(hex: string): string {
    return mostReadable(hex, ['#FFF', '#191919']).toHexString();
  }
}
