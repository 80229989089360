<app-route-loading-indicator
  class="u-display-block"
></app-route-loading-indicator>
<ng-container *ngIf="(header | async)?.show">
  <app-header-nav
    class="u-display-block bg-color-white mat-elevation-z1"
    [ngClass]="{ 'u-position-relative': true }"
    [ngClass.gt-xs]="{ 'u-position-fixed': true }"
    [ngStyle]="{ top: '0', left: '0', right: '0', 'z-index': '4' }"
    [title]="routeTitle"
    [user]="user | async"
  >
  </app-header-nav>
</ng-container>
<div fxHide="true" [fxHide.gt-xs]="false">
  <div *ngIf="isEmptyState" [ngStyle]="{ height: '60px' }"></div>
  <div *ngIf="!isEmptyState">
    <div *ngIf="(header | async)?.show" [ngStyle]="{ height: '60px' }"></div>
  </div>
</div>
<router-outlet class="u-display-block"></router-outlet>
