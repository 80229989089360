import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { isReservedSubdomain } from '@libs/src/utilities/url.utilities';

@Injectable()
export class NonRootGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    if (!isReservedSubdomain()) {
      // TODO: instead, do: return this.router.createUrlTree(['/login']);
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
